import { Injectable } from '@angular/core'
import { MessageService } from './message.service'

@Injectable({
  providedIn: 'root',
})
export class StorageService {

  constructor(private messageService: MessageService) { }

  storageSupported = typeof (Storage) !== 'undefined'

  store = (key, data) => {
    this.storageSupported
      ? localStorage.setItem(key, JSON.stringify(data))
      : console.log('no storage supported')
  }

  get = (key) => {
    return this.storageSupported && JSON.parse(localStorage.getItem(key))
  }

  remove = (key) => {
    this.storageSupported
      ? localStorage.removeItem(key)
      : console.log('no storage supported')
  }
}
