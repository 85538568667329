import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { LoginModalComponent } from 'src/app/modals/login/login.component'
import { AddCountryModalComponent } from 'src/app/modals/addCountry/addCountry.component'
import { AddConservationModalComponent } from 'src/app/modals/addConservation/addConservation.component'
import { AddShelflifeModalComponent } from 'src/app/modals/addShelflife/addShelflife.component'
import { OrderModalComponent } from 'src/app/modals/order/order.component'
import { AddAccountModalComponent } from 'src/app/modals/addAccount/addAccount.component'
import { AddRoleModalComponent } from 'src/app/modals/addRole/addRole.component'
import { ProductDetailsModalComponent } from 'src/app/modals/productDetails/productDetails.component'
import { EditProductModalComponent } from 'src/app/modals/editProduct/editProduct.component'
import { EditAccountModalComponent } from 'src/app/modals/editAccount/editAccount.component'

@Injectable({
  providedIn: 'root',
})

export class ModalService {

  constructor(public dialog: MatDialog) { }

  createForceLoginDialog = (message = 'MODAL.LOGIN.REAUTHENTICATE') => {
    return this.loginDialog(message, true)
  }

  createLoginDialog = (message = undefined) => {
    return this.loginDialog(message)
  }

  private loginDialog = (message = '', forceful = false) => {
    return this.dialog.open(LoginModalComponent, {
      width: '500px',
      disableClose: forceful,
      data: {
        message,
        forceful
      }
    }).afterClosed()
  }

  createAddCountryModal = () => {
    return this.dialog.open(AddCountryModalComponent, {
      width: '500px',
      //disableClose: true
    }).afterClosed()
  }

  createAddConservationModal = () => {
    return this.dialog.open(AddConservationModalComponent, {
      width: '500px'
    }).afterClosed()
  }

  createAddShelflifeModal = () => {
    return this.dialog.open(AddShelflifeModalComponent, {
      width: '500px'
    }).afterClosed()
  }

  createAddAccountModal = () => {
    return this.dialog.open(AddAccountModalComponent, {
      width: '500px'
    }).afterClosed()
  }

  createAddRoleModal = () => {
    return this.dialog.open(AddRoleModalComponent, {
      width: '500px'
    }).afterClosed()
  }

  createOrderModal = () => {
    return this.dialog.open(OrderModalComponent, {
      width: '800px'
    }).afterClosed()
  }

  createProductDetailModal = (product, buyable = true) => {
    return this.dialog.open(ProductDetailsModalComponent, {
      width: '600px',
      data: {
        product,
        buyable
      }
    }).afterClosed()
  }

  createEditProductModal = (product) => {
    return this.dialog.open(EditProductModalComponent, {
      width: '500px',
      data: {
        product
      }
    }).afterClosed()
  }

  createEditAccountModal = (account) => {
    return this.dialog.open(EditAccountModalComponent, {
      width: '500px',
      data: {
        account
      }
    }).afterClosed()
  }
}
