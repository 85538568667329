import { Component } from '@angular/core'

@Component({
    selector: 'pageNotFound',
    templateUrl: './pageNotFound.component.html',
    styleUrls: ['./pageNotFound.component.scss']
})
export class PageNotFoundComponent {
}

