import { Component } from '@angular/core'
import { AuthenticationService } from 'src/app/services/authentication.service'
import { LanguageService } from 'src/app/services/language.service'
import { ClientService } from 'src/app/services/client.service'
import { BasketService } from 'src/app/services/basket.service'
import { find, map } from 'lodash'

@Component({
    selector: 'profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

    user
    orders

    orderHistory
    deliveryAddresses

    constructor(private authenticationService: AuthenticationService, private languageService: LanguageService, private clientService: ClientService, private basketService: BasketService) {
        this.initSubscriptions()
    }

    private initSubscriptions = () => {
        this.authenticationService.castUser.subscribe(user => this.user = user)
        this.basketService.castOrderHistory.subscribe(orderHistory => {
            this.orderHistory = orderHistory
            this.mapDeliveryAddressToOrders()
        })
        this.clientService.castClientDeliveryAddress.subscribe(address => {
            this.deliveryAddresses = address
            this.mapDeliveryAddressToOrders()
        })
    }

    private mapDeliveryAddressToOrders = () => {
        if (!this.orderHistory || !this.deliveryAddresses) return
        this.orders = map(this.orderHistory, order => {
            const address = find(this.deliveryAddresses, a => Number(a.id) === Number(order.delivery_address_id))
            if (!address) return order
            return {
                ...order,
                delivery_address_name: address.name || '',
                delivery_address_street: address.street || '',
                delivery_address_number: address.number || '',
                delivery_address_postal_code: address.postal_code || '',
                delivery_address_city: address.city || '',
                delivery_address_type: address.type || '',
            }
        })
    }

    setLanguage = (lang) => {
        this.languageService.setLanguage(lang)
    }

    getLanguageActive = (lang) => {
        if (this.languageService.getLanguage() === lang) return 'active'
    }

    getLanguage = () => {
        return this.languageService.getLanguage()
    }

    hasAccessToResource = this.authenticationService.hasAccessToResource
}

