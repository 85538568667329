import { Component, ViewChild } from '@angular/core'
import { ConservationService } from 'src/app/services/conservation.service'
import { ModalService } from 'src/app/services/modal.service'
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material'
import { Conservation } from '../../interfaces/interfaces'

@Component({
    selector: 'conservationList',
    templateUrl: './conservationList.component.html',
    styleUrls: ['./conservationList.component.scss']
})
export class ConservationListComponent {

    @ViewChild(MatPaginator) paginator: MatPaginator
    @ViewChild(MatSort) sort: MatSort

    conservations: MatTableDataSource<Conservation> = new MatTableDataSource([])
    conservationsError

    constructor(private conservationService: ConservationService, private modalService: ModalService) {
        this.initSubscriptions()
    }

    private initSubscriptions = () => {
        this.conservationService.castConservations.subscribe(this.setConservations)
        this.conservationService.castConservationsError.subscribe(error => this.conservationsError = error)
    }

    private setConservations = (conservations) => setTimeout(() => {
        this.conservations.paginator = this.paginator
        this.conservations.sort = this.sort
        this.conservations.data = conservations || []
    })

    applyFilter = (filterValue: string) => {
        this.conservations.filter = filterValue.trim().toLowerCase()
        if (this.conservations.paginator) {
            this.conservations.paginator.firstPage()
        }
    }

    createNewConservation = () => {
        this.modalService.createAddConservationModal()
    }
}