import { Component } from '@angular/core'
import { AuthenticationService } from 'src/app/services/authentication.service'
import { Router } from '@angular/router'

@Component({
    selector: 'admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent {

    fullPath
    groups
    GROUPS = [
        {
            groupname: 'Algemeen',
            links: [
                {
                    routerLink: '/admin/story',
                    image: '../../../assets/images/icons/color/color-story.svg',
                    name: 'Nieuwsitems'
                }
            ]
        },
        {
            groupname: 'Account',
            links: [
                {
                    routerLink: '/admin/account',
                    image: '../../../assets/images/icons/color/color-account.svg',
                    name: 'Accounts'
                },
                {
                    routerLink: '/admin/client',
                    image: '../../../assets/images/icons/color/color-client.svg',
                    name: 'Klanten',
                },
                {
                    routerLink: '/admin/role',
                    image: '../../../assets/images/icons/color/color-policy.svg',
                    name: 'Rollen'
                }
            ]
        },
        {
            groupname: 'Product',
            links: [
                {
                    routerLink: '/admin/tds',
                    image: '../../../assets/images/icons/color/color-document.svg',
                    name: 'Technische fiches'
                },
                {
                    routerLink: '/admin/product',
                    image: '../../../assets/images/icons/color/color-cookie.svg',
                    name: 'Producten'
                },
                {
                    routerLink: '/admin/country',
                    image: '../../../assets/images/icons/color/color-belgium.svg',
                    name: 'Landen'
                },
                {
                    routerLink: '/admin/conservation',
                    image: '../../../assets/images/icons/color/color-conservation.svg',
                    name: 'Bewaarvoorschriften'
                },
                {
                    routerLink: '/admin/shelflife',
                    image: '../../../assets/images/icons/color/color-calendar.svg',
                    name: 'Houdbaarheden'
                }
            ]
        },
        // {
        //     groupname: 'Tools',
        //     links: [
        //         {
        //             routerLink: '/admin/reminders',
        //             image: '../../../assets/images/icons/color/color-notepad.svg',
        //             name: 'Reminders'
        //         },
        //         {
        //             routerLink: '/admin/statistics',
        //             image: '../../../assets/images/icons/color/color-graph.svg',
        //             name: 'Statistieken'
        //         },
        //         {
        //             routerLink: '/admin/docs',
        //             image: '../../../assets/images/icons/color/color-documentation.svg',
        //             name: 'Documentatie'
        //         },
        //     ]
        // }
    ]

    constructor(private authenticationService: AuthenticationService, private router: Router) {
        router.events.subscribe((val) => {
            this.fullPath = val['url']
        })
        this.authenticationService.castUser.subscribe(user => {
            this.filterLinks()
        })
    }

    private filterLinks = () => {
        this.groups = this.GROUPS
            .map(g => ({
                groupname: g.groupname.toUpperCase(),
                links: g.links.filter(link => this.authenticationService.hasAccessToPath(link.routerLink))
            }))
            .filter(el => el.links.length > 0)
    }
}