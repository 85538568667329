import { Component, Input, Output, ViewChild, ElementRef } from '@angular/core'
import { LanguageService } from 'src/app/services/language.service'
import { EventEmitter } from '@angular/core'
import { get } from 'lodash'

@Component({
  selector: 'productSelect-dropdown',
  templateUrl: './productSelect.component.html',
  styleUrls: ['./productSelect.component.scss']
})
export class ProductSelectComponent {
  @ViewChild('input1') inputEl: ElementRef

  @Output('selected') emitter: EventEmitter<Object> = new EventEmitter<Object>()

  @Input('products') set setInitoValue(products) {
    this.products = products;
  }
  @Input('loading') set setInitoLoadingValue(loading) {
    if (loading) this.loading = loading
  }
  @Input('title') set setInitoTitleValue(title) {
    if (title) this.title = title
  }

  title
  loading = false
  products
  filter
  selectedProduct

  constructor(private languageService: LanguageService) { }

  notify = () => {
    this.filter = ''
    this.emitter.emit(this.selectedProduct)
  }

  opened = () => setTimeout(() => {
    this.inputEl.nativeElement.focus()
  })

  getProductDescription = (p) => {
    const lang = this.languageService.getLanguage()
    const defaultLang = this.languageService.getDefaultLanguage()

    return get(p, `description_${lang}`) || get(p, `description_${defaultLang}`)
  }
}
