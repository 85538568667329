import { Component, Inject } from '@angular/core'
import { Router } from '@angular/router'
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material'
import { AuthenticationService } from 'src/app/services/authentication.service'
import { MessageService } from 'src/app/services/message.service'
import { BicobelHttpService } from 'src/app/api/bicobelHttp.service'
import { AccountService } from 'src/app/services/account.service'
import { FormControl } from '@angular/forms';

export interface DialogData {
    account: {
        roles
    }
}

@Component({
    selector: 'editAccount-dialog',
    templateUrl: './editAccount.component.html',
    styleUrls: ['./editAccount.component.scss']
})
export class EditAccountModalComponent {

    rolesControl = new FormControl()
    roles
    selectedRoles = []

    account
    saveAccountError

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public dialogRef: MatDialogRef<EditAccountModalComponent>,
        private authenticationService: AuthenticationService, private router: Router, private messageService: MessageService,
        public dialog: MatDialog, private bicobelHttpService: BicobelHttpService, private accountService: AccountService
    ) {
        this.account = data.account
        this.subscribeToCasts()
        this.initControls()
    }

    private subscribeToCasts = () => {
        this.accountService.castRoles.subscribe(roles => {
            const filteredRoles = roles.filter(role => this.accountService.FILTERED_ROLES.includes(role.name))
            this.roles = filteredRoles || []
            // should never fail because can't remove role if in use by account (api policy) -> no ghost roles
            this.rolesControl.setValue(this.data.account.roles.map(o => this.roles.find(role => role.name === o)))
        })
    }

    private initControls = () => {
        this.rolesControl.valueChanges.subscribe(roles => this.selectedRoles = roles)
    }

    closeDialog = () => this.dialogRef.close()

    saveAccount = () => {
        const rolesToAdd = this.selectedRoles.filter(role => !this.account.roles.includes(role.name)).map(role => role.name)
        const rolesToRemove = this.account.roles
            .filter(role => !this.selectedRoles.map(r => r.name).includes(role))
            .filter(role => this.accountService.FILTERED_ROLES.includes(role))

        this.messageService.createConfirmationDialog('MODAL.EDIT_ACCOUNT.CONFIRMATION_TITLE', 'MODAL.EDIT_ACCOUNT.CONFIRMATION_MESSAGE').subscribe(agrees => {
            if (!agrees) return
            this.accountService.addRolesToAccount(this.account.id, rolesToAdd)
                .catch(err => {
                    this.messageService.new(err, 'danger')
                })
            this.accountService.removeRolesForAccount(this.account.id, rolesToRemove)
                .catch(err => {
                    this.messageService.new(err, 'danger')
                })
            this.closeDialog()
        })
    }
}