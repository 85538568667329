import { Component, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { StoryService } from 'src/app/services/story.service'
import { ModalService } from 'src/app/services/modal.service'
import { MessageService } from 'src/app/services/message.service'
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material'
import { Story } from 'src/app/interfaces/interfaces'
import { sortBy } from 'lodash'

@Component({
    selector: 'storyList',
    templateUrl: './storyList.component.html',
    styleUrls: ['./storyList.component.scss']
})
export class StoryListComponent {
    @ViewChild(MatPaginator) paginator: MatPaginator
    @ViewChild(MatSort) sort: MatSort

    stories: MatTableDataSource<Story> = new MatTableDataSource([])
    storiesError

    constructor(
        private storyService: StoryService,
        private router: Router,
        private messageService: MessageService
    ) {
        this.initSubscriptions()
    }

    private initSubscriptions = () => {
        this.storyService.castStories.subscribe(this.setStories)
        this.storyService.castStoriesError.subscribe(error => this.storiesError = error)
    }

    private setStories = (stories) => setTimeout(() => {
        this.stories.paginator = this.paginator
        this.stories.sort = this.sort
        this.stories.data = sortBy(stories || [], 'sequence_nr') || []
    })

    applyFilter = (filterValue: string) => {
        this.stories.filter = filterValue.trim().toLowerCase()
        if (this.stories.paginator)
            this.stories.paginator.firstPage()
    }

    createNewStory = () => {
        this.router.navigate(['admin/story/new'])
    }

    deleteById = (id) => {
        this.messageService.createConfirmationDialog('Story verwijderen?', 'Weet u zeker dat u de story wilt verwijderen?').subscribe(agrees => {
            if (!agrees) return
            this.storyService.deleteStoryById(Number(id)).catch(err => this.storiesError = err)
        })
    }

    editById = (id) => {
        this.router.navigate(['admin/story/edit'], { queryParams: { id: id } })
    }

    moveStory = (id, direction) => {
        this.storyService.moveStory(id, direction)
            .then(() => this.storiesError = undefined)
            .catch(err => {
                if (err == "STORYCANNOTHIGHER" || "STORYCANNOTLOWER") {
                    return
                }

                this.storiesError = err
            })
    }
}