import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export interface DialogData {
    title: string
    text: string
}

@Component({
    selector: 'dialog-overview-example-dialog',
    templateUrl: './confirmation.component.html',
})
export class ConfirmationModalComponent {
    
    constructor( public dialogRef: MatDialogRef<ConfirmationModalComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    closeDialog = () => this.dialogRef.close()
}