import { Component, ViewEncapsulation } from '@angular/core'
import { StoryService } from '../../services/story.service'
import { LanguageService } from 'src/app/services/language.service'
import { environment } from '../../../environments/environment'

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HomeComponent {

    showTodo = false

    stories
    storiesError

    constructor(private storyService: StoryService, private languageService: LanguageService) {
        this.showTodo = environment.show_todo
        this.storyService.castStories.subscribe((stories) => this.stories = stories ? stories : [])
        this.storyService.castStoriesError.subscribe(error => this.storiesError = error)
    }

    getTranslations = (story, part) => {
        const lang = this.languageService.getLanguage()
        if (story[part + '_' + lang]) return story[part + '_' + lang]
        return `${story[part + '_' + this.languageService.getDefaultLanguage()]}`
    }

    getContent = (s) => {
        return this.getTranslations(s, 'content')
    }

    getTitle = (s) => {
        return this.getTranslations(s, 'title')
    }

}