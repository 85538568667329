import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'currency',
    pure: false
})
export class CurrencyFormatPipe implements PipeTransform {
    transform(money: number, format: String): String {
        if (format === 'euro') {
            return Math.round(money).toString()
        } else if (format === 'cents') {
            const cents = (money * 100) % 100
            return cents >= 10
                ? cents.toString()
                : '0' + cents
        } else {
            return money.toString()
        }
    }
}