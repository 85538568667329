import { Component } from '@angular/core'
import { AuthenticationService } from 'src/app/services/authentication.service'
import { Router } from '@angular/router'

@Component({
    // selector bug: don't use 'label' since it will override the standard <label> html selector. Causing complete chaos
    selector: 'labelo',
    templateUrl: './label.component.html',
    styleUrls: ['./label.component.scss']
})
export class LabelComponent {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
    ) { }

    isLabelmanager = () => {
        return this.authenticationService.hasRole('labelmanager')
    }

    showEditLink = () => {
        return this.router.url.split('?')[0] === '/label/edit'
    }
}
