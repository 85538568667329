import { Component, ViewChild } from '@angular/core'
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material'
import { Product } from 'src/app/interfaces/interfaces'
import { ProductService } from 'src/app/services/product.service'
import { TdsService } from 'src/app/services/tds.service'

@Component({
    selector: 'tdsAsPdfList',
    templateUrl: './tdsAsPdfList.component.html',
    styleUrls: ['./tdsAsPdfList.component.scss']
})

export class TdsAsPdfListComponent {

    @ViewChild(MatPaginator) paginator: MatPaginator
    @ViewChild(MatSort) sort: MatSort

    products: MatTableDataSource<Product> = new MatTableDataSource([])
    productsError

    pdfError
    fetchingPdf = false

    constructor(private productService: ProductService, private tdsService: TdsService) {
        this.initSubscriptions()
    }

    private initSubscriptions = () => {
        this.productService.castProducts.subscribe(this.setProducts)
        this.productService.castProductsError.subscribe(error => this.productsError = error)
    }

    private setProducts = (products) => setTimeout(() => {
        this.products.paginator = this.paginator
        this.products.sort = this.sort
        this.products.data = products || []
    })

    applyFilter = (filterValue: string) => {
        this.products.filter = filterValue.trim().toLowerCase()
        if (this.products.paginator) {
            this.products.paginator.firstPage()
        }
    }

    downloadTdsAsPdf = async (productId) => {
        this.pdfError = undefined
        this.fetchingPdf = true
        this.tdsService.getTdsAsPdf(productId)
            .then(this.getUrlFromBuffer)
            .then(url => {
                window.open(url)
                this.fetchingPdf = false
            }).catch(err => {
                this.pdfError = 'LABEL.TDSASPDF.ERROR_FETCHING_PDF'
                this.fetchingPdf = false
            })
    }

    private getUrlFromBuffer = async (obj) => {
        const file = new Blob([obj], { type: 'application/pdf' })
        return URL.createObjectURL(file)
    }
}