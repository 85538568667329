import { Component, ViewChild } from '@angular/core'
import { ClientService } from 'src/app/services/client.service'
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material'
import { Client } from '../../interfaces/interfaces'

@Component({
    selector: 'clientList',
    templateUrl: './clientList.component.html',
    styleUrls: ['./clientList.component.scss']
})
export class ClientListComponent {

    @ViewChild(MatPaginator) paginator: MatPaginator
    @ViewChild(MatSort) sort: MatSort

    clients: MatTableDataSource<Client> = new MatTableDataSource([])
    clientsError

    constructor(private clientService: ClientService) {
        this.initSubscriptions()
    }

    private initSubscriptions = () => {
        this.clientService.castClients.subscribe(this.setClients)
        this.clientService.castClientsError.subscribe(error => this.clientsError = error)
    }

    private setClients = (clients) => setTimeout(() => {
        this.clients.paginator = this.paginator
        this.clients.sort = this.sort
        this.clients.data = clients || []
    })

    applyFilter = (filterValue: string) => {
        this.clients.filter = filterValue.trim().toLowerCase()
        if (this.clients.paginator) {
            this.clients.paginator.firstPage()
        }
    }
}