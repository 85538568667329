import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LabelService } from '../../../services/label.service'
import { MessageService } from 'src/app/services/message.service'
import { checkLabelRequirements, calculateEan8Checksum } from '../../../../lib/label'

@Component({
    selector: 'labelEdit',
    templateUrl: './labelEdit.component.html',
    styleUrls: ['./labelEdit.component.scss']
})

export class LabelEditComponent implements OnInit {

    sub
    WORDLENGTH = 100
    editedLabel

    errorSaveLabel
    formErrors

    constructor(private messageService: MessageService, private labelService: LabelService, private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService) {
        this.authenticationService.lockNavigation()
    }

    ngOnInit() {
        this.sub = this.route.queryParams.subscribe(params => {
            const id = Number(params['id'])
            if (id) this.editedLabel = this.labelService.getLabelById(id)
            if (!id || !this.editedLabel) return this.router.navigate(['label/overview'])
        })
    }

    saveLabel = () => {
        this.formErrors = checkLabelRequirements(this.editedLabel)
        if (!this.formErrors.complete) return

        const objectToPost = {
            id: this.editedLabel.id,
            code: this.editedLabel.code,
            product_id: this.editedLabel.product_id,
            description_nl: this.editedLabel.description_nl,
            description_fr: this.editedLabel.description_fr,
            price: this.editedLabel.price,
            unit: this.editedLabel.unit,
            amount: this.editedLabel.amount,
            barcode: this.editedLabel.barcode.substring(0, 7),
            can_print: this.editedLabel.can_print,
            footer: this.editedLabel.footer
        }

        this.messageService.createConfirmationDialog('LABEL.EDIT.CONFIRM_SAVE_TITLE', 'LABEL.EDIT.CONFIRM_SAVE').subscribe(agrees => {
            if (!agrees) return
            this.labelService.saveEditedLabel(objectToPost)
                .then(res => {
                    this.errorSaveLabel = undefined
                    this.authenticationService.unlockNavigation()
                    this.redirectToLabelOverview()
                }).catch(err => this.errorSaveLabel = err)
        })
    }

    redirectToLabelOverview = () => {
        this.router.navigate(['/label/overview'])
    }

    calculateEan8Checksum = calculateEan8Checksum
}