import { Component } from '@angular/core'
import { MessageService } from '../../services/message.service'

@Component({
    selector: 'message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss']
})
export class MessageComponent {

    messages = []

    constructor(private messageService: MessageService) {
    }

    ngOnInit() {
        this.messages = this.messageService.getMessages()
    }

    instantClose = (m) => {
        this.messageService.instantClose(m)
    }

    closeAll = () => {
        this.messageService.closeAll()
    }
}

