import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material'
import { BicobelHttpService } from 'src/app/api/bicobelHttp.service'
import { ProductService } from 'src/app/services/product.service'
import { BasketService } from 'src/app/services/basket.service'
import { trim, clamp } from 'lodash'

export interface DialogData {
    product: object,
    buyable: boolean
}

@Component({
    selector: 'productDetails-dialog',
    templateUrl: './productDetails.component.html',
    styleUrls: ['./productDetails.component.scss']
})
export class ProductDetailsModalComponent {

    product
    buyable

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public dialogRef: MatDialogRef<ProductDetailsModalComponent>,
        private productService: ProductService,
        private bicobelHttpService: BicobelHttpService,
        private basketService: BasketService,
        public dialog: MatDialog,
    ) {
        this.product = data.product
        this.buyable = data.buyable
        this.fetchImageFromServer(this.product)
    }

    closeDialog = () => this.dialogRef.close()

    private fetchImageFromServer = (product) => {
        if (product.imageReceived || !product.image_reference) return
        this.bicobelHttpService.get(`/image/product/${trim(product.image_reference)}`, { responseType: 'blob' as 'json' })
            .then(imageBlob => {
                const url = URL.createObjectURL(imageBlob)
                product.internalImageUrl = url
                product.imageReceived = true
            })
    }

    getProductDescription = () => {
        return this.productService.getProductDescription(this.product)
    }

    getPrettyProduct = () => {
        return JSON.stringify(this.data.product, null, 2)
    }

    addProductToBasket = (product) => {
        this.basketService.addProduct(product)
    }

    removeProductFromBasket = (product) => {
        this.basketService.deleteProduct(product)
    }

    inBasket = (product) => {
        return this.basketService.inBasket(product)
    }

    addOneToProduct = (product) => {
        product.orderAmount++
    }

    removeOneFromProduct = (product) => {
        clamp(--product.orderAmount, 0)
    }
}