import { Injectable, OnDestroy } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { BicobelHttpService } from 'src/app/api/bicobelHttp.service'
import { find } from 'lodash'
import { AuthenticationService } from './authentication.service'

@Injectable({
  providedIn: 'root',
})
export class TdsService implements OnDestroy {

  private userSubscription

  private tds = new BehaviorSubject(undefined)
  castTds = this.tds.asObservable()

  private tdsError = new BehaviorSubject(undefined)
  castTdsError = this.tdsError.asObservable()

  constructor(
    private bicobelHttpService: BicobelHttpService,
    private authenticationService: AuthenticationService
  ) {
    this.init()
  }

  init = () => {
    this.userSubscription = this.authenticationService.castUser.subscribe(() => {
      this.reset()
      this.authenticationService.hasAccessToResource('tds') && this.fetchTds()
    })
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe()
  }

  reset = () => {
    this.tds.next(undefined)
    this.tdsError.next(undefined)
  }

  fetchTds = () => {
    return this.bicobelHttpService.get('/tds')
      .then((tds) => {
        this.tds.next(Object.values(tds))
        this.tdsError.next(undefined)
        return tds
      })
      .catch(err => {
        this.tdsError.next(err)
        throw err
      })
  }

  saveNewTds = (newTds) => {
    return this.bicobelHttpService.post('/tds', newTds)
      .then(this.fetchTds)
  }

  saveEditedTds = async (editedTds) => {
    return this.bicobelHttpService.put('/tds', editedTds)
      .then(this.fetchTds)
  }

  getTds = () => {
    return this.tds.value
  }

  getTdsById = (tdsId: Number) => {
    return find(this.tds.value, tds => Number(tds.id) === tdsId)
  }

  deleteById = async (id) => {
    return this.bicobelHttpService.delete(`/tds/${id}`)
      .then(this.fetchTds)
  }

  getTdsAsPdf = (productId) => {
    return this.bicobelHttpService.get(`/tdspdf/${productId}`, { responseType: 'arraybuffer' })
  }
}
