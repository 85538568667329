import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { get } from 'lodash'
// import * as Sentry from "@sentry/browser"
import { BICOBEL_ERROR_CODES, HTTP_ERROR_CODES } from './errorcodes'

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService {
    private DEBUGMODE = environment.debugmode
    private BICOBEL_ERROR_CODES = BICOBEL_ERROR_CODES
    private HTTP_ERROR_CODES = HTTP_ERROR_CODES

    handleHttpError = (error) => {
        const isBicobelError = !!get(error, ['error', 'errorcode'])

        const parsedError = isBicobelError
            ? this.getErrorMessageByBicobelErrorResponse(error)
            : this.getErrorMessageByHttpErrorResponse(error)

        this.log(parsedError)
        this.debug(parsedError)

        return parsedError.message
    }

    private getErrorMessageByHttpErrorResponse = (error) => {
        const httpErrorCode = Number(get(error, ['status'], 'UNKNOWN_ERROR'))
        const errorMessage = get(this.HTTP_ERROR_CODES, [httpErrorCode], httpErrorCode)
        const url = get(error, ['url'])

        return {
            message: `(${httpErrorCode}) | ${errorMessage} |: ${url}`,
            code: httpErrorCode,
            type: 'http',
            original: error
        }
    }

    private getErrorMessageByBicobelErrorResponse = (error) => {
        const bicobelErrorCode = get(error, ['error', 'errorcode'], 'UNKNOWN_ERROR')
        const errorMessage = get(this.BICOBEL_ERROR_CODES, [bicobelErrorCode], bicobelErrorCode)

        return {
            message: errorMessage,
            code: bicobelErrorCode,
            type: 'bicobel_api',
            original: error
        }
    }

    private log(event) {
        const original = event.original
        const message = get(original, 'error.message', 'Unknown error')

        // Sentry.withScope(function (scope) {
        //     scope.setExtras(original)
        //     scope.setLevel(Sentry.Severity.Info)

        //     Sentry.captureMessage(message)
        // })

    }

    private debug(error) {
        if (this.DEBUGMODE) console.error(error)
    }
}