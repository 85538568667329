import { Injectable, OnDestroy } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { BicobelHttpService } from 'src/app/api/bicobelHttp.service'
import { LanguageService } from './language.service'
import { find, filter, get } from 'lodash'
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService implements OnDestroy {

  private userSubscription

  private products = new BehaviorSubject(undefined)
  castProducts = this.products.asObservable()

  private productsError = new BehaviorSubject(undefined)
  castProductsError = this.productsError.asObservable()

  constructor(
    private bicobelHttpService: BicobelHttpService,
    private languageService: LanguageService,
    private authenticationService: AuthenticationService
  ) {
    this.init()
  }

  init = () => {
    this.userSubscription = this.authenticationService.castUser.subscribe(() => {
      this.reset()
      this.authenticationService.hasAccessToResource('product') && this.fetchProducts()
    })
  }

  ngOnDestroy(){
    this.userSubscription.unsubscribe()
  }

  reset = () => {
    this.products.next(undefined)
    this.productsError.next(undefined)
  }

  fetchProducts = async () => {
    return this.bicobelHttpService.get('/product')
      .then(products => {
        this.products.next(Object.values(products))
        this.productsError.next(undefined)
        return products
      })
      .catch(err => {
        this.productsError.next(err)
      })
  }

  fetchProductById = async (id: Number) => {
    return this.bicobelHttpService.get('/product/' + id)
  }

  getProducts = () => {
    return this.products.value
  }

  getProductById = (productId: Number) => {
    return find(this.products.value, p => Number(p.id) === productId)
  }

  getProductsWithNoTds = () => {
    return filter(this.products.value, o => !o.tds_id)
  }

  saveProduct = (product_id, tds_id) => {
    const objectToPost = { id: product_id, tds_id: tds_id }
    return this.bicobelHttpService.put('/product', objectToPost)
      .then(this.fetchProducts)
  }

  getProductDescription = (p) => {
    const lang = this.languageService.getLanguage()
    const defaultLang = this.languageService.getDefaultLanguage()

    return get(p, `description_${lang}`) || get(p, `description_${defaultLang}`)
  }
}
