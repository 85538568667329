import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { environment } from '../../environments/environment'
import { ConfirmationModalComponent } from 'src/app/modals/confirmation/confirmation.component'

@Injectable({
  providedIn: 'root',
})

export class MessageService {

  constructor(public dialog: MatDialog) { }
  private DEBUGMODE = environment.debugmode
  messages = []
  history = []

  getMessages = () => {
    return this.messages
  }

  debug = (message) => {
    if (this.DEBUGMODE) this.new(message, 'debug')
  }

  new = (message, type = 'neutral', duration = 10) => {
    this.history.push({
      timestamp: new Date(),
      message,
      type,
    })

    const newMessage = this.createNewMessage(message, type, duration)
    this.messages.push(newMessage)
  }

  instantClose = (m) => {
    m.instantClose()
  }

  closeAll = () => {
    this.messages.forEach(m => m.instantClose())
  }

  /**
   *
   * @param message => message to display
   * @param type => neutral (default) || danger || success || debug
   * @param duration => time to display
   */
  private createNewMessage = (message, type, duration) => {
    const m = {
      message,
      type,
      visibility: '',
      timer: duration,
      instantClose: () => {
        m.timer = 1
      },
      close: () => {
        m.timer--
        if (m.timer < 1) m.visibility = 'fadeout'
        if (m.timer < 0) {
          clearInterval(interval)
          this.messages.splice(this.messages.indexOf(m), 1)
        }
      },
    }
    const interval = setInterval(m.close, 1000)
    return m
  }

  createConfirmationDialog = (title, text) => {
    return this.dialog.open(ConfirmationModalComponent, {
      width: '400px',
      data: { title, text }
    }).afterClosed()
  }
}