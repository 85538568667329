import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material'
import { MessageService } from 'src/app/services/message.service'
import { AccountService } from 'src/app/services/account.service'

@Component({
    selector: 'addRole-dialog',
    templateUrl: './addRole.component.html',
    styleUrls: ['./addRole.component.scss']
})
export class AddRoleModalComponent {

    role = {
        name: undefined,
        description: undefined
    }
    formError
    loading = false

    addRoleError

    constructor(private messageService: MessageService,
        private accountService: AccountService,
        public dialogRef: MatDialogRef<AddRoleModalComponent>
    ) { }

    closeDialog = () => this.dialogRef.close()

    addRole() {
        if (!this.checkMinimalRequirements()) return
        this.addRoleError = undefined
        this.messageService.createConfirmationDialog('Opslaan', 'Weet u zeker dat u deze rol wilt toevoegen?').subscribe(agrees => {
            if (!agrees) return
            this.accountService.createRole(this.role)
                .then(res => {
                    this.closeDialog()
                }).catch(err => {
                    this.addRoleError = err
                })
        })
    }

    checkMinimalRequirements() {
        this.formError = undefined
        if (!this.role.name || !this.role.description) {
            this.formError = 'Niet alle velden zijn ingevuld'
            return false
        }
        return true
    }
}