import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { MessageService } from 'src/app/services/message.service'
import { StoryService } from 'src/app/services/story.service'
import { AuthenticationService } from 'src/app/services/authentication.service'
import { isEmpty } from 'lodash'

@Component({
  selector: 'storyEdit',
  templateUrl: './storyEdit.component.html',
  styleUrls: ['./storyEdit.component.scss']
})
export class StoryEditComponent implements OnInit {

  sub
  story

  formError = {}
  errorSaveStory

  constructor(private storyService: StoryService, private messageService: MessageService, private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService) {
    this.authenticationService.lockNavigation()
  }

  ngOnInit() {
    this.sub = this.route.queryParams.subscribe(params => {
      const id = Number(params['id'])
      const story = this.storyService.getStoryById(id)
      if (!story) this.redirect()
      this.story = { ...story }
    })
  }

  redirect = () => {
    this.router.navigate(['admin'])
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  saveStory() {
    if (!this.checkMinimalRequirements()) return
    this.messageService.createConfirmationDialog('Opslaan', 'Weet u zeker dat u deze story wilt overschrijven?').subscribe(agrees => {
      if (!agrees) return
      this.errorSaveStory = undefined
      this.storyService.postNewStory(this.story)
        .then(res => {
          this.messageService.new('succesvol overschreven story')
          this.authenticationService.unlockNavigation()
          this.redirect()
        }).catch(err => {
          this.errorSaveStory = err
        })
    })
  }

  checkMinimalRequirements() {
    this.formError = {}
    if (!this.story.title_nl || this.story.title_nl === '')
      this.formError['title_nl'] = 'Geef een nederlandse titel in.'
    if (!this.story.content_nl || this.story.content_nl === '')
      this.formError['content_nl'] = 'Geef een nederlands inhoud in.'
    if (!this.story.title_fr || this.story.title_fr === '')
      this.formError['title_fr'] = 'Geef een franse titel in.'
    if (!this.story.content_fr || this.story.content_fr === '')
      this.formError['content_fr'] = 'Geef een franse inhoud in.'
    return isEmpty(this.formError)
  }
}
