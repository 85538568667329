import { Component, Inject } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { BicobelHttpService } from 'src/app/api/bicobelHttp.service';
import { MessageService } from 'src/app/services/message.service';
import { ConservationService } from 'src/app/services/conservation.service';

export interface DialogData {
    title: string
    text: string
}

@Component({
    selector: 'addConservation-dialog',
    templateUrl: './addConservation.component.html',
    styleUrls: ['./addConservation.component.scss']
})
export class AddConservationModalComponent {

    conservation = {
        code: null,
        name_nl: null,
        name_fr: null,
        name_en: null,
        name_de: null
    }
    formError
    addConservationError
    loading = false

    constructor(private messageService: MessageService,
        private conservationService: ConservationService,
        public dialogRef: MatDialogRef<AddConservationModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    }

    closeDialog = () => this.dialogRef.close()

    addConservation() {
        if (!this.checkMinimalRequirements()) return
        this.messageService.createConfirmationDialog('Opslaan', 'Weet u zeker dat u dit land wilt toevoegen?').subscribe(agrees => {
            if (!agrees) return
            this.addConservationError = undefined
            this.conservationService.addConservation(this.conservation)
                .then(res => {
                    this.closeDialog()
                }).catch(err => {
                    this.addConservationError = err
                })
        })
    }

    checkMinimalRequirements() {
        this.formError = undefined
        if (!this.conservation.code || !this.conservation.name_fr || !this.conservation.name_nl || !this.conservation.name_en || !this.conservation.name_de) {
            this.formError = 'Niet alle velden zijn ingevuld'
            return false
        }
        return true
    }
}