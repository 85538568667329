export const HTTP_ERROR_CODES = {
    '0': 'ERROR.EXTERNAL.STATUSCODE_ZERO',
    '400': 'ERROR.EXTERNAL.BAD_REQUEST',
    '404': 'ERROR.EXTERNAL.CANT_FIND_URL',
    '403': 'ERROR.EXTERNAL.FORBIDDEN',
    '500': 'ERROR.EXTERNAL.INTERAL_SERVER_ERROR',
}

export const BICOBEL_ERROR_CODES = {
    LABELSONLYCLIENT: 'ERROR.EXTERNAL.LABELSONLYCLIENT',
    NOLOGINORPWPROVIDED: 'ERROR.EXTERNAL.NOLOGINORPWPROVIDED',
    ACCOUNTTOIMPERSONATENOTFOUND: 'ERROR.EXTERNAL.ACCOUNTTOIMPERSONATENOTFOUND',
    UNABLETOUNIMPERSONATE: 'ERROR.EXTERNAL.UNABLETOUNIMPERSONATE',
    ACCOUNTTOUNIMPERSONATENOTFOUND: 'ERROR.EXTERNAL.ACCOUNTTOUNIMPERSONATENOTFOUND',
    USERNAMENOTPROVIDED: 'ERROR.EXTERNAL.USERNAMENOTPROVIDED',
    MAILCANNOTBESENT: 'ERROR.EXTERNAL.MAILCANNOTBESENT',
    NONEWPASSWORDPROVIDED: 'ERROR.EXTERNAL.NONEWPASSWORDPROVIDED',
    NOACCOUNTFOUND: 'ERROR.EXTERNAL.NOACCOUNTFOUND',
    INCORRECTPASSWORD: 'ERROR.EXTERNAL.INCORRECTPASSWORD',
    NOAUTHPROVIDED: 'ERROR.EXTERNAL.NOAUTHPROVIDED',
    EXISTINGACOUNTNAME: 'ERROR.EXTERNAL.EXISTINGACOUNTNAME',
    EXISTINGROLE: 'ERROR.EXTERNAL.EXISTINGROLE',
    DBERROR: 'ERROR.EXTERNAL.DBERROR',
    GENERALDBERROR: 'ERROR.EXTERNAL.GENERALDBERROR',
    ROLECANNOTBEDELTED: 'ERROR.EXTERNAL.ROLECANNOTBEDELTED',
    ROLESTILLINUSE: 'ERROR.EXTERNAL.ROLESTILLINUSE',
    ACCOUNTNOTFOUND: 'ERROR.EXTERNAL.ACCOUNTNOTFOUND',
    ROLENOTFOUND: 'ERROR.EXTERNAL.ROLENOTFOUND',
    CLIENTNOTHAVINGROLE: 'ERROR.EXTERNAL.CLIENTNOTHAVINGROLE',
    ACCOUNTNOTVALID: 'ERROR.EXTERNAL.ACCOUNTNOTVALID',
    CLIENTNOTFOUND: 'ERROR.EXTERNAL.CLIENTNOTFOUND',
    LABELRENDERERROR: 'ERROR.EXTERNAL.LABELRENDERERROR',
    LABELNOTFOUD: 'ERROR.EXTERNAL.LABELNOTFOUD',
    NOTDSFOUNDFORPRODUCT: 'ERROR.EXTERNAL.NOTDSFOUNDFORPRODUCT',
    INPUTERROR: 'ERROR.EXTERNAL.INPUTERROR',
    NOACCESSTOPRODUCT: 'ERROR.EXTERNAL.NOACCESSTOPRODUCT',
    LABELNOKTDS: 'ERROR.EXTERNAL.LABELNOKTDS',
    LABELSONLYCLIENT: 'ERROR.EXTERNAL.LABELSONLYCLIENT',
    LABELNOTFOUND: 'ERROR.EXTERNAL.LABELNOTFOUND',
    LABELCANPRINTNOBOOL: 'ERROR.EXTERNAL.LABELCANPRINTNOBOOL',
    NONEXISTINGADDRESS: 'ERROR.EXTERNAL.NONEXISTINGADDRESS',
    STORYALREADYEXISTS: 'ERROR.EXTERNAL.STORYALREADYEXISTS',
    SAVESTORYERROR: 'ERROR.EXTERNAL.SAVESTORYERROR',
    CONSERVATIONNOTFOUND: 'ERROR.EXTERNAL.CONSERVATIONNOTFOUND',
    CONSERVATIONALREADYEXISTS: 'ERROR.EXTERNAL.CONSERVATIONALREADYEXISTS',
    SAVECONSERVATIONERROR: 'ERROR.EXTERNAL.SAVECONSERVATIONERROR',
    COUNTRYNOTFOUND: 'ERROR.EXTERNAL.COUNTRYNOTFOUND',
    COUNTRYALREADYEXISTS: 'ERROR.EXTERNAL.COUNTRYALREADYEXISTS',
    SAVECOUNTRYERROR: 'ERROR.EXTERNAL.SAVECOUNTRYERROR',
    SHELFLIFENOTFOUND: 'ERROR.EXTERNAL.SHELFLIFENOTFOUND',
    NOTIMPLEMENTED: 'ERROR.EXTERNAL.NOTIMPLEMENTED',
    TDSIDMISSING: 'ERROR.EXTERNAL.TDSIDMISSING',
    TDSNOTFOUND: 'ERROR.EXTERNAL.TDSNOTFOUND',
    TDSALREADYEXISTS: 'ERROR.EXTERNAL.TDSALREADYEXISTS',
    DATABASEEXCEPTION: 'ERROR.EXTERNAL.DATABASEEXCEPTION',
    NOTOKEN: 'ERROR.EXTERNAL.NOTOKEN',
    ACCESSNOTALLOWED: 'ERROR.EXTERNAL.ACCESSNOTALLOWED',
    TOKENFORWRONGOCCASION: 'ERROR.EXTERNAL.TOKENFORWRONGOCCASION',
    EXPIREDTOKEN: 'ERROR.EXTERNAL.EXPIREDTOKEN',
    UNVALIDTOKEN: 'ERROR.EXTERNAL.UNVALIDTOKEN',
    UNVALIDTOKENSIGNATURE: 'ERROR.EXTERNAL.UNVALIDTOKENSIGNATURE',
    ACCOUNTCLAIMNOTFOUND: 'ERROR.EXTERNAL.ACCOUNTCLAIMNOTFOUND',
    ROLECANNOTBEDELTED: "ERROR.EXTERNAL.ROLECANNOTBEDELETED",
    INCORRECTPASSWORD: "ERROR.EXTERNAL.INCORRECTPASSWORD"
}