import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import { StorageService } from '../services/storage.service'
import { ErrorHandlerService } from './errorHandler.service'
import { environment } from '../../environments/environment'
import { BehaviorSubject, of, throwError, Subject } from 'rxjs'
import { tap, map, retry, catchError, takeUntil } from 'rxjs/operators'
import { tokenValid } from '../../lib/jwt'

@Injectable({
    providedIn: 'root',
})

export class BicobelHttpService {

    private BICOBEL_DATA = environment.apiUrl

    private cancelSubscriptions = new Subject()

    private token = new BehaviorSubject(undefined)
    castToken = this.token.asObservable()

    private fetching = new BehaviorSubject(0)
    castFetching = this.fetching.asObservable()

    private tokenExpired = new BehaviorSubject(undefined)
    castTokenExpired = this.tokenExpired.asObservable()

    constructor(private http: HttpClient, private storageService: StorageService, private errorHandler: ErrorHandlerService) {
        this.getTokenFromStorage()
    }

    private getTokenFromStorage = () => {
        const token = this.storageService.get('auth')
        tokenValid(token)
            ? this.token.next(token)
            : this.removeToken()
    }

    private setToken = (token) => {
        if (!token) return
        this.token.next(token)
        this.tokenExpired.next(false)
        this.storageService.store('auth', token)
    }

    removeToken = () => {
        this.token.next(undefined)
        this.storageService.remove('auth')
    }

    logout = () => {
        this.removeToken()
        this.cancelAllCurrentHttpCalls()
    }

    cancelAllCurrentHttpCalls = () => {
        this.cancelSubscriptions.next()
        this.fetching.next(0)
    }

    /**
     * HTTP functions to be called from outside this class. These will redirect to 'httpAction'
     */
    get = (path, options = {}) => {
        return this.httpAction('get', path, null, options)
    }
    post = (path, content, options = {}) => {
        return this.httpAction('post', path, content, options)
    }
    put = (path, content, options = {}) => {
        return this.httpAction('put', path, content, options)
    }
    delete = (path, options = {}) => {
        return this.httpAction('delete', path, null, options)
    }

    /**
     * Combined HTTP request function, only to be called from internally. Please call other HTTP methods from outside this class.
     * Every call will update JWT token and put in storage.
     * @param operation (type: string): 'get', 'post', 'put' or 'delete
     * @param path (type: string): 'path to append to server url (BICOBEL_DATA)'
     * @param content (type: object): json object to 'post' or 'put'
     * @param options (type: object): json object containing extra parameters. eg. responseType, contentType, security
     */
    private httpAction = (operation, path, content = null, options: any = {}) => {
        const {
            security = true,
            contentType = 'application/json',
            responseType = 'json',
        } = options

        let headers = new HttpHeaders()
        headers = headers.set('Content-Type', contentType)

        if (security) {
            if (!tokenValid(this.token.value)) {
                this.tokenExpired.next('i\'m not longer valid, please force a reauthentication or logout')
                // Doesn't matter as long as it double bangs to true. Just triggers an update in appcomponent.ts
                return
            }
            headers = headers.set('Security', this.token.value)
        }

        const url = `${this.BICOBEL_DATA}${path}`
        const call = (() => {
            switch (operation) {
                case 'get':
                    return this.http.get(url, { headers, responseType, observe: 'response' })
                case 'post':
                    return this.http.post(url, content, { headers, responseType, observe: 'response' })
                case 'put':
                    return this.http.put(url, content, { headers, responseType, observe: 'response' })
                case 'delete':
                    return this.http.delete(url, { headers, responseType, observe: 'response' })
            }
        })()

        this.fetching.next(this.fetching.value + 1)

        return call
            .pipe(
                takeUntil(this.cancelSubscriptions),
                tap(res => {
                    this.fetching.next(this.fetching.value - 1)

                    const security = res.headers.get('Security')
                    if (security) this.setToken(security)
                }),
                map(res => res.body),
                retry(1),
                catchError((error: HttpErrorResponse) => {
                    this.fetching.next(this.fetching.value - 1)

                    return throwError(this.errorHandler.handleHttpError(error))
                })
            )
            .toPromise()
    }
}
