import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { MessageService } from './message.service'
import { StorageService } from './storage.service'
import { DateTimeAdapter } from 'ng-pick-datetime'
import { includes, head } from 'lodash'

@Injectable({
  providedIn: 'root',
})
export class LanguageService {

  languages = ['nl', 'en', 'fr', 'de', 'it'] // allowing other languages enables them if operating system is in that language
  language
  data = {}

  constructor(private messageService: MessageService, private storageService: StorageService, private http: HttpClient, private dateTimeAdapter: DateTimeAdapter<any>) {
    this.initiateLanguage()
  }

  private initiateLanguage() {
    const storedLanguage = this.storageService.get('language')
    storedLanguage
      ? this.setLanguage(storedLanguage)
      : this.setLanguage(navigator.language.toLowerCase().split('-')[0])
  }

  getLanguage = () => {
    return this.language
  }

  setLanguage = (lang) => {
    this.language = includes(this.languages, lang)
      ? lang
      : head(this.languages)

    this.updateStorage()
    this.fetchData()
    this.dateTimeAdapter.setLocale(lang)
  }

  fetchData = () => new Promise((resolve, reject) => {
    const langPath = `../../../assets/i18n/${this.language || 'nl'}.json`

    const setData = (data = {}) => {
      this.data = data
      resolve(data)
    }

    this.http.get(langPath).subscribe(setData, setData)
  })

  getDefaultLanguage = () => {
    return this.languages[0]
  }

  private updateStorage = () => {
    this.storageService.store('language', this.language)
  }
}