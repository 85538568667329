import { Component } from '@angular/core'
import { LanguageService } from 'src/app/services/language.service'

@Component({
    selector: 'faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent {

    constructor(private languageService: LanguageService) { }

    /* Maybe eventually move this to server? - some future update*/
    questions = [
        {
            title: 'FAQ.QUESTIONS.QUESTION_1.TITLE',
            content: 'FAQ.QUESTIONS.QUESTION_1.CONTENT.PARAGRAPH_1'
        },
        {
            title: 'FAQ.QUESTIONS.QUESTION_2.TITLE',
            content: 'FAQ.QUESTIONS.QUESTION_2.CONTENT.PARAGRAPH_1'
        },
        {
            title: 'FAQ.QUESTIONS.QUESTION_3.TITLE',
            content: 'FAQ.QUESTIONS.QUESTION_3.CONTENT.PARAGRAPH_1'
        },
        {
            title: 'FAQ.QUESTIONS.QUESTION_4.TITLE',
            content: 'FAQ.QUESTIONS.QUESTION_4.CONTENT.PARAGRAPH_1'
        },
        {
            title: 'FAQ.QUESTIONS.QUESTION_5.TITLE',
            content: 'FAQ.QUESTIONS.QUESTION_5.CONTENT.PARAGRAPH_1'
        },
        {
            title: 'FAQ.QUESTIONS.QUESTION_6.TITLE',
            content: 'FAQ.QUESTIONS.QUESTION_6.CONTENT.PARAGRAPH_1'
        },
    ]

    getTitle = (question) => question['title_' + this.languageService.getLanguage()] || '...'
    getContent = (question) => question['content_' + this.languageService.getLanguage()] || '...'
}