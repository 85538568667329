import { Injectable, OnDestroy } from '@angular/core'
import { BicobelHttpService } from 'src/app/api/bicobelHttp.service'
import { AuthenticationService } from './authentication.service'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ShelflifeService implements OnDestroy {

  private userSubscription

  private shelflives = new BehaviorSubject(undefined)
  castShelflives = this.shelflives.asObservable()

  private shelflivesError = new BehaviorSubject(undefined)
  castShelflivesError = this.shelflivesError.asObservable()

  constructor(
    private bicobelHttpService: BicobelHttpService,
    private authenticationService: AuthenticationService
  ) {
    this.init()
  }

  init = () => {
    this.userSubscription = this.authenticationService.castUser.subscribe(() => {
      this.reset()
      this.authenticationService.hasAccessToResource('shelflife') && this.fetchShelflives()
    })
  }

  ngOnDestroy(){
    this.userSubscription.unsubscribe()
  }

  reset = () => {
    this.shelflives.next(undefined)
    this.shelflivesError.next(undefined)
  }

  fetchShelflives = () => {
    return this.bicobelHttpService.get('/shelflife')
      .then(shelflives => {
        this.shelflives.next(Object.values(shelflives))
        this.shelflivesError.next(undefined)
        return shelflives
      })
      .catch(err => {
        this.shelflivesError.next(err)
      })
  }

  getShelflives = () => {
    return this.shelflives.value
  }

  addShelflife = async (shelflife) => {
    return this.bicobelHttpService.post('/shelflife', shelflife)
      .then(this.fetchShelflives)
  }
}
