import { Component } from '@angular/core'
import { LanguageService } from '../../services/language.service'
import { AuthenticationService } from '../../services/authentication.service'
import { MessageService } from '../../services/message.service'
import { BasketService } from '../../services/basket.service'
import { ModalService } from 'src/app/services/modal.service'
import { BicobelHttpService } from 'src/app/api/bicobelHttp.service'
import { trigger, animate, style, transition, query, group, keyframes } from '@angular/animations'
import { has } from 'lodash'

@Component({
    selector: 'header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [
        trigger('orderAnimation', [
            transition(':increment', group([
                style({ opacity: '1', top: '100px' }),
                animate('1s ease-out', keyframes([
                    style({ opacity: '1', top: '20px', offset: 0.8 }),
                    style({ left: '10px', width: '10px', height: '10px', opacity: '0', top: '0px', offset: 1 })
                ]))
            ])),
            /*transition(':decrement', group([
                style('*'),
                animate('1s ease-out', keyframes([
                    style({ opacity: '1', top: '5px', offset: 0.05 }),
                    style({ opacity: '1', top: '100px', offset: 0.9 }),
                    style({ opacity: '0', top: '100px', offset: 1 })
                ]))
            ]))*/
        ])
    ]
})
export class HeaderComponent {

    basketLength
    user
    isFetching

    constructor(
        private modalService: ModalService,
        public languageService: LanguageService,
        private authenticationService: AuthenticationService,
        private messageService: MessageService,
        private basketService: BasketService,
        private bicobelHttpService: BicobelHttpService
    ) {
        this.initSubscription()
    }

    private initSubscription = () => {
        this.authenticationService.castUser.subscribe(user => this.user = user)
        this.basketService.castOrder.subscribe(order => this.basketLength = order.products.length)
        this.bicobelHttpService.castFetching.subscribe(fetching => this.isFetching = fetching > 0)
    }

    isLoggedIn = () => {
        return !!this.user
    }

    getUsername = () => {
        return this.user.username
    }

    hasAccessToPath = (path) => {
        return this.authenticationService.hasAccessToPath(path)
    }

    login = () => {
        return this.modalService.createLoginDialog()
    }

    logout = () => {
        return this.authenticationService.logout()
    }

    getOrderLength = () => {
        return this.basketService.getOrder().products.length
    }

    isImpersonating = () => {
        return has(this.user, 'impersonator_id')
    }

    unimpersonate = () => {
        this.messageService.createConfirmationDialog('Stop impersonating', 'Stop impersonating this user?').subscribe(agrees => {
            if (!agrees) return

            this.authenticationService.unimpersonate().catch(err => {
                this.messageService.new(err, 'alert')
            })
        })
    }
}