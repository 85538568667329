import { Component, OnInit } from '@angular/core'
import { LabelService } from '../../../services/label.service'
import { AuthenticationService } from 'src/app/services/authentication.service'
import { ModalService } from 'src/app/services/modal.service'
import { trim } from 'lodash'

@Component({
    selector: 'labelPrint',
    templateUrl: './labelPrint.component.html',
    styleUrls: ['./labelPrint.component.scss']
})
export class LabelPrintComponent {

    labels
    labelError

    selectedLabel
    expirationDate
    lotnumber
    formError

    labelurl
    fetchingPdf = false
    pdfSettings = '#view=fit&scrollbar=0&toolbar=1&statusbar=0'
    errorPdf

    constructor(
        private modalService: ModalService,
        private authenticationService: AuthenticationService,
        private labelService: LabelService,
    ) {
        this.initSubscriptions()
        this.getExampleLabel()
    }

    private getExampleLabel = () => {
        this.labelService.getExamplePdf()
            .then(((res) => {
                this.errorPdf = undefined
                this.labelurl = this.getUrlFromBuffer(res) + this.pdfSettings
            }))
            .catch(err => this.errorPdf = err)
    }

    private initSubscriptions = () => {
        this.labelService.castLabels.subscribe(labels => {
            this.labels = labels ? labels.filter(l => l.can_print) : undefined
            this.labelError = undefined
        })
        this.labelService.castLabelsError.subscribe(err => this.labelError = err)
    }

    generateLabel = () => {
        if (!this.checkMinimalRequirements()) return

        this.fetchingPdf = true

        this.labelService.getPdf(this.selectedLabel.id, this.expirationDate, this.lotnumber)
            .then((res) => {
                this.fetchingPdf = false
                this.labelurl = this.getUrlFromBuffer(res) + this.pdfSettings
                this.errorPdf = undefined
            }).catch(err => {
                this.labelurl = null
                this.fetchingPdf = false
                this.errorPdf = err
            })
    }

    private checkMinimalRequirements() {
        this.formError = undefined
        if (!this.selectedLabel) {
            this.formError = 'LABEL.PRINT.NO_LABEL_SELECTED'
            return false
        }
        return true
    }

    private getUrlFromBuffer = (obj) => {
        const file = new Blob([obj], { type: 'application/pdf' })
        return URL.createObjectURL(file)
    }

    isLabelmanager = () => this.authenticationService.hasRole('labelmanager')

    hasClient = () => this.authenticationService.getClientId() ? true : false
}
