import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { StoryService } from 'src/app/services/story.service'
import { MessageService } from 'src/app/services/message.service'
import { AuthenticationService } from 'src/app/services/authentication.service'
import { isEmpty } from 'lodash'

@Component({
  selector: 'storyNew',
  templateUrl: './storyNew.component.html',
  styleUrls: ['./storyNew.component.scss']
})
export class StoryNewComponent {

  story = {
    title_nl: '',
    title_fr: '',
    content_nl: '',
    content_fr: '',
    public: false, // to be implemented
  }

  editorOptions = {
    placeholder: 'insert content...'
  }

  errorSaveStory
  formError = {}

  constructor(private storyService: StoryService, private router: Router, private messageService: MessageService, private authenticationService: AuthenticationService) {
    this.authenticationService.lockNavigation()
  }

  addStory = () => {
    if (!this.checkMinimalRequirements()) return
    this.messageService.createConfirmationDialog('Opslaan', 'Weet u zeker dat u deze story wilt toevoegen?').subscribe(agrees => {
      if (!agrees) return
      this.errorSaveStory = undefined
      this.storyService.postNewStory({ ...this.story, public: 1 })
        .then(res => {
          this.authenticationService.unlockNavigation()
          this.router.navigate(['admin/story'])
        }).catch(err => {
          this.errorSaveStory = err
        })
    })
  }

  private checkMinimalRequirements = () => {
    this.formError = {}
    if (!this.story.title_nl || this.story.title_nl === '')
      this.formError['title_nl'] = 'Geef een nederlandse titel in.'
    if (!this.story.content_nl || this.story.content_nl === '')
      this.formError['content_nl'] = 'Geef een nederlands inhoud in.'
    if (!this.story.title_fr || this.story.title_fr === '')
      this.formError['title_fr'] = 'Geef een franse titel in.'
    if (!this.story.content_fr || this.story.content_fr === '')
      this.formError['content_fr'] = 'Geef een franse inhoud in.'
    return isEmpty(this.formError)
  }
}
