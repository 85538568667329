import { Injectable, OnDestroy } from '@angular/core'
import { BicobelHttpService } from 'src/app/api/bicobelHttp.service'
import { AuthenticationService } from './authentication.service'
import { BehaviorSubject } from 'rxjs'
import { find } from 'lodash'

@Injectable({
  providedIn: 'root',
})
export class StoryService implements OnDestroy {

  private userSubscription

  private stories = new BehaviorSubject(undefined)
  castStories = this.stories.asObservable()

  private storiesError = new BehaviorSubject(undefined)
  castStoriesError = this.storiesError.asObservable()

  constructor(
    private bicobelHttpService: BicobelHttpService,
    private authenticationService: AuthenticationService
  ) {
    this.init()
  }

  init = () => {
    this.userSubscription = this.authenticationService.castUser.subscribe(() => {
      this.reset()
      this.authenticationService.hasAccessToResource('story') && this.fetchStories()
    })
  }

  ngOnDestroy(){
    this.userSubscription.unsubscribe()
  }

  reset = () => {
    this.stories.next(undefined)
    this.storiesError.next(undefined)
  }

  fetchStories = () => {
    const loggedIn = this.authenticationService.isLoggedIn()

    return this.bicobelHttpService.get('/story', { security: loggedIn })
      .then(stories => {
        this.stories.next(stories)
        this.storiesError.next(undefined)
        return stories
      })
      .catch(err => {
        this.storiesError.next(err)
        throw err
      })
  }

  postNewStory = (story) => {
    return this.bicobelHttpService.post('/story', story)
      .then(this.fetchStories)
  }

  getStories = () => {
    return this.stories.value
  }

  getStoryById = (id: Number) => {
    return find(this.stories.value, story => Number(story.id) === Number(id))
  }

  deleteStoryById = async (id: Number) => {
    return this.bicobelHttpService.delete(`/story/${id}`)
      .then(this.fetchStories)
  }

  moveStory = async (id: Number, direction) => {
    return this.bicobelHttpService.get(`/story/${id}/${direction}`)
      .then(this.fetchStories)
  }
}