import { Component } from '@angular/core'
import { Router } from "@angular/router"
import { AuthenticationService } from 'src/app/services/authentication.service'
import { MessageService } from '../../../services/message.service'
import { ProductService } from '../../../services/product.service'
import { LabelService } from '../../../services/label.service'
import { pick } from 'lodash'
import { checkLabelRequirements, calculateEan8Checksum } from '../../../../lib/label'

@Component({
    selector: 'labelNew',
    templateUrl: './labelNew.component.html',
    styleUrls: ['./labelNew.component.scss']
})

export class LabelNewComponent {

    WORDLENGTH = 100

    errorSaveLabel

    formErrors

    newLabel
    products
    labelId

    constructor(private messageService: MessageService, private productService: ProductService, private labelService: LabelService, private router: Router, private authenticationService: AuthenticationService) {
        this.initSubscription()
    }

    private initSubscription = () => {
        this.productService.castProducts.subscribe((products) => {
            this.products = products || []
        })
    }

    setNewLabel = (product) => {
        this.authenticationService.lockNavigation()
        this.newLabel = {
            code: '',
            ...product,
            product_id: product.id,
            price: product.price_ex_tax,
            barcode: '0000000',
            footer: '',
            can_print: true
        }
    }

    saveLabel = () => {
        this.formErrors = checkLabelRequirements(this.newLabel)
        if (!this.formErrors.complete) return

        const picked = pick(this.newLabel, ['code', 'product_id', 'description_nl', 'description_fr', 'price', 'unit', 'amount', 'barcode', 'can_print', 'footer'])
        const post = Object.assign(picked, { barcode: picked.barcode.slice(0, 7) })

        this.messageService.createConfirmationDialog('LABEL.NEW.BTN_SAVE_LABEL', 'LABEL.NEW.SAVE_CHANGES').subscribe(agrees => {
            if (!agrees) return
            this.labelService.saveNewLabel(post)
                .then(res => {
                    this.errorSaveLabel = undefined
                    this.messageService.new(`LABEL.NEW.MESSAGE_SAVE_SUCCESS| ${res}`, 'success', 5)
                    this.authenticationService.unlockNavigation()
                    this.redirectToLabelOverview()
                })
                .catch(err => this.errorSaveLabel = err)
        })
    }

    redirectToLabelOverview = () => {
        this.router.navigate(['/label/overview'])
    }

    calculateEan8Checksum = calculateEan8Checksum
}