import { Component, ViewChild } from '@angular/core'
import { ShelflifeService } from 'src/app/services/shelflife.service'
import { ModalService } from 'src/app/services/modal.service'
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material'
import { Shelflife } from 'src/app/interfaces/interfaces'

@Component({
    selector: 'shelflifeList',
    templateUrl: './shelflifeList.component.html',
    styleUrls: ['./shelflifeList.component.scss']
})
export class ShelflifeListComponent {
    @ViewChild(MatPaginator) paginator: MatPaginator
    @ViewChild(MatSort) sort: MatSort

    shelflives: MatTableDataSource<Shelflife> = new MatTableDataSource([])
    shelflivesError

    constructor(private shelflifeService: ShelflifeService, private modalService: ModalService) {
        this.initSubscriptions()
    }

    private initSubscriptions = () => {
        this.shelflifeService.castShelflives.subscribe(this.setShelflives)
        this.shelflifeService.castShelflivesError.subscribe(error => {
            this.shelflivesError = error
        })
    }

    private setShelflives = (shelflives) => setTimeout(() => {
        this.shelflives.paginator = this.paginator
        this.shelflives.sort = this.sort
        this.shelflives.data = shelflives || []
    })

    applyFilter = (filterValue: string) => {
        this.shelflives.filter = filterValue.trim().toLowerCase()
        if (this.shelflives.paginator)
            this.shelflives.paginator.firstPage()
    }

    createNewShelflife = () => {
        this.modalService.createAddShelflifeModal()
    }
}