import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material'
import { MessageService } from 'src/app/services/message.service'
import { AccountService } from 'src/app/services/account.service'
import { ClientService } from 'src/app/services/client.service'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { FormControl } from '@angular/forms'
import { toLower, includes, isObject } from 'lodash'

@Component({
    selector: 'addAccount-dialog',
    templateUrl: './addAccount.component.html',
    styleUrls: ['./addAccount.component.scss']
})
export class AddAccountModalComponent {
    clientControl = new FormControl()
    rolesControl = new FormControl()

    account = {
        username: undefined,
        password: undefined,
        client_id: undefined
    }
    clients
    filteredClients

    roles
    selectedRoles = []

    formError
    loading = false

    addAccountError

    constructor(
        private clientService: ClientService,
        private messageService: MessageService,
        private accountService: AccountService,
        public dialogRef: MatDialogRef<AddAccountModalComponent>
    ) {
        this.subscribeToCasts()
    }

    private subscribeToCasts() {
        this.clientService.castClients.subscribe(clients => {
            this.clients = clients || []
            this.initControls()
        })
        this.accountService.castRoles.subscribe(roles => {
            const filteredRoles = roles.filter(role => this.accountService.FILTERED_ROLES.includes(role.name))
            this.roles = filteredRoles || []
        })
    }

    private initControls() {
        this.clientControl.valueChanges
            .pipe(debounceTime(200), distinctUntilChanged())
            .subscribe(this.updateFilterClients)
        this.rolesControl.valueChanges.subscribe(roles => this.selectedRoles = roles)
    }

    private updateFilterClients = (value = '') => {
        setTimeout(() => {
            if (isObject(value)) {
                this.account.client_id = Number(value['id'])
                return
            }

            const filter = toLower(value)
            this.account.client_id = undefined

            this.filteredClients = this.clients
                .filter(o => includes(toLower(o.name), filter))
                .splice(0, 50)
        }, 100)
    }

    displayClientsFn = (client) => client ? client.name : undefined

    closeDialog = () => this.dialogRef.close()

    addAccount() {
        if (!this.checkMinimalRequirements()) return
        this.messageService.createConfirmationDialog('Opslaan', 'Weet u zeker dat u dit account wilt toevoegen?').subscribe(agrees => {
            if (!agrees) return
            this.addAccountError = undefined
            this.accountService.createAccount(this.account)
                .then((newAccountId) => {
                    addRolesToAccount(newAccountId)
                    this.closeDialog()
                }).catch(err => {
                    this.addAccountError = err
                })
        })

        const addRolesToAccount = (newAccountId) => {
            this.accountService.addRolesToAccount(newAccountId, this.selectedRoles.map(role => role.name))
                .catch(err => {
                    this.messageService.new(err, 'danger')
                })
        }
    }

    checkMinimalRequirements() {
        this.formError = undefined
        if (!this.account.username || !this.account.password || !this.account.client_id) {
            this.formError = 'Niet alle velden zijn ingevuld'
            return false
        } else if (this.account.client_id && !this.clientService.getClientExistsById(this.account.client_id)) {
            this.formError = 'Onjuiste client meegegeven'
            return false
        }
        return true
    }
}