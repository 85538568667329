import { Component, Inject } from '@angular/core'
import { ResetPasswordModalComponent } from '../resetPassword/resetPassword.component'
import { Router } from '@angular/router'
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material'
import { AuthenticationService } from 'src/app/services/authentication.service'
import { MessageService } from 'src/app/services/message.service'
import { trim, get, isEmpty } from 'lodash'

export interface DialogData {
    message: string
    forceful: boolean
}

@Component({
    selector: 'login-dialog',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginModalComponent {

    username
    password
    errorMessage
    loading = false
    isSuccess = false
    message
    forceful
    disableUsername = false

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public dialogRef: MatDialogRef<LoginModalComponent>,
        private authenticationService: AuthenticationService,
        private messageService: MessageService,
        public dialog: MatDialog
    ) {
        this.message = data.message
        this.forceful = data.forceful
        this.subscribeToUser()
    }

    private subscribeToUser() {
        this.authenticationService.castUser.subscribe(user => {
            if (isEmpty(user)) return
            this.username = get(user, ['username'])
            this.disableUsername = true
        })
    }

    login() {
        this.username = trim(this.username)
        this.password = trim(this.password)
        if (!this.username || !this.password || this.username === '' || this.password === '') {
            this.errorMessage = 'Vul je gebruikersnaam en wachtwoord in'
            return
        }
        this.loading = true
        this.authenticationService.login(this.username, this.password).then(res => {
            this.loading = false
            this.isSuccess = true
            this.dialogRef.close(this.isSuccess)
        }).catch(err => {
            this.errorMessage = err
            this.password = ''
            this.loading = false
        })
    }

    closeDialog() {
        if (!this.forceful) return this.dialogRef.close()
        this.askConfirmation()
    }

    askConfirmation() {
        this.messageService.createConfirmationDialog('Bent u zeker dat u inloggen wilt annuleren?', 'Onopgeslagen werk gaat verloren en u wordt omgeleid naar de bezoekerspagina.').subscribe(agrees => {
            if (!agrees) return
            this.authenticationService.forceRedirectHome()
            this.dialogRef.close()
        })
    }

    resetPassword = () => {
        this.dialog.open(ResetPasswordModalComponent, {
            width: '500px',
            disableClose: true
        })
    }
}