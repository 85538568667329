import { chain, includes } from 'lodash'

const permissions = [
  {
    role: 'admin',
    regions: ['*'],
    resources: ['*']
  },
  {
    role: 'user',
    regions: [
      '/basket',
      '/shop',
      '/profile'
    ],
    resources: [
      'product',
      'orderHistory',
      'deliveryAddress',
      'order'
    ]
  },
  {
    role: 'labeluser',
    regions: [
      '/label',
      '/label/print',
      '/profile',
      '/label/tdsAsPdfList'
    ],
    resources: [
      'label'
    ]
  },
  {
    role: 'labelmanager',
    regions: [
      '/label/overview',
      '/label/edit',
      '/label/new',
      '/label',
      '/label/print',
      '/label/tdsAsPdfList',
      '/profile'
    ],
    resources: [
      'product',
      'label'
    ]
  },
  {
    role: 'accountmanager',
    regions: [
      '/admin',
      '/admin/account',
      '/admin/client',
      '/admin/role',
      '/admin/client',
      '/admin/role',
      '/profile'
    ],
    resources: [
      'account',
      'role',
      'client'
    ]
  },
  {
    role: 'tdsmanager',
    regions: [
      '/admin',
      '/admin/tds',
      '/admin/tds/new',
      '/admin/tds/edit',
      '/admin/product',
      '/admin/country',
      '/admin/conservation',
      '/admin/shelflife',
      '/profile'
    ],
    resources: [
      'country',
      'shelflife',
      'conservation',
      'tds',
      'product'
    ]
  },
  {
    role: 'storymanager',
    regions: [
      '/admin',
      '/admin/story',
      '/admin/story/new',
      '/admin/story/edit',
      '/profile'
    ],
    resources: [
      'story'
    ]
  }
]

export const publicResources = ['story']

export const getAllowedResources = (roles) => {
  return chain(permissions)
    .filter(perm => includes(roles, perm.role))
    .map(perm => perm.resources)
    .flatten()
    .uniq()
    .concat(publicResources)
    .value()
}

export const getAllowedRegions = (roles) => {
  return chain(permissions)
    .filter(perm => includes(roles, perm.role))
    .map(perm => perm.regions)
    .flatten()
    .uniq()
    .value()
}