import { Component, ViewChild } from '@angular/core'
import { AccountService } from 'src/app/services/account.service'
import { ModalService } from 'src/app/services/modal.service'
import { ClientService } from 'src/app/services/client.service'
import { MessageService } from 'src/app/services/message.service'
import { AuthenticationService } from 'src/app/services/authentication.service'
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material'
import { Account } from '../../interfaces/interfaces'

@Component({
    selector: 'accountList',
    templateUrl: './accountList.component.html',
    styleUrls: ['./accountList.component.scss']
})
export class AccountListComponent {
    @ViewChild(MatPaginator) paginator: MatPaginator
    @ViewChild(MatSort) sort: MatSort

    accounts: MatTableDataSource<Account> = new MatTableDataSource([])

    accountsError
    deleteAccountError

    constructor(
        private accountService: AccountService, private authenticationService: AuthenticationService,
        private modalService: ModalService, private clientService: ClientService,
        private messageService: MessageService
    ) {
        this.initSubscriptions()
    }

    private initSubscriptions = () => {
        this.accountService.castAccounts.subscribe(this.setAccounts)
        this.accountService.castAccountsError.subscribe(error => this.accountsError = error)
    }

    private setAccounts = (accounts) => setTimeout(() => {
        this.accounts.paginator = this.paginator
        this.accounts.sort = this.sort
        this.accounts.data = accounts || []
    })

    applyFilter = (filterValue) => {
        this.accounts.filter = filterValue.trim().toLowerCase()
        if (this.accounts.paginator)
            this.accounts.paginator.firstPage()
    }

    createNewAccount = () => {
        this.modalService.createAddAccountModal()
    }

    getClientNameById = (id: Number) => {
        const client = this.clientService.getClientById(id)
        return client ? client.name : ''
    }

    editAccount = (account) => {
        this.modalService.createEditAccountModal(account)
    }

    deleteById = (id) => {
        this.messageService.createConfirmationDialog('Account verwijderen', 'Dit account verwijderen?')
            .subscribe(agrees => {
                if (!agrees) return
                this.accountService.deleteAccount(Number(id))
                    .then(res => this.deleteAccountError = undefined)
                    .catch(err => this.deleteAccountError = err)
            })
    }

    impersonateById = (id) => {
        this.messageService.createConfirmationDialog('Account overnemen', 'Wilt u inloggen met dit account?')
            .subscribe(agrees => {
                if (!agrees) return
                this.authenticationService.impersonate(id)
                    .catch(err => {
                        this.messageService.new(err, 'alert')
                    })
            })
    }
}