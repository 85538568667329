import { Component, OnInit, HostListener } from '@angular/core'
import { BicobelHttpService } from './api/bicobelHttp.service'
import { ModalService } from './services/modal.service'
import { MessageService } from './services/message.service'
import { Router, NavigationEnd } from '@angular/router'
import { AuthenticationService } from './services/authentication.service'
import { filter } from 'rxjs/operators'
import { environment } from '../environments/environment'

const showBackground = environment.show_background

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  showBackground = showBackground

  constructor(
    private bicobelHttpService: BicobelHttpService,
    private modalService: ModalService,
    private messageService: MessageService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!!this.authenticationService.isNavigiationLocked()) {
      $event.returnValue = true
    }
  }

  ngOnInit() {
    // scroll to top on route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) return
      window.scrollTo(0, 0)
    })

    // check if server is live
    this.bicobelHttpService.get('/ping', { security: false, responseType: 'text' })
      .catch(() => this.messageService.new('Server appears to be offline', 'danger'))

    // force user login if token is invalid
    this.bicobelHttpService.castTokenExpired
      .pipe(filter(x => !!x))
      .subscribe(() => {
        this.modalService.createForceLoginDialog('MODAL.LOGIN.REAUTHENTICATE')
      })
  }
}
