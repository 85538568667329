import { Component, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { MessageService } from 'src/app/services/message.service'
import { ProductService } from 'src/app/services/product.service'
import { TdsService } from 'src/app/services/tds.service'
import { ModalService } from 'src/app/services/modal.service'
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material'
import { Product } from '../../interfaces/interfaces'
import { find } from 'lodash'

@Component({
  selector: 'productList',
  templateUrl: './productList.component.html',
  styleUrls: ['./productList.component.scss']
})
export class ProductListComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort

  products: MatTableDataSource<Product> = new MatTableDataSource([])
  productsError

  tds
  tdsError

  productData

  constructor(private productService: ProductService, private tdsService: TdsService, private router: Router, private messageService: MessageService, private modalService: ModalService) {
    this.initSubscriptions()
  }

  applyFilter = (filterValue: string) => {
    this.products.filter = filterValue.trim().toLowerCase()

    if (this.products.paginator) {
      this.products.paginator.firstPage()
    }
  }

  private initSubscriptions = () => {
    this.productService.castProducts
      //.pipe(distinctUntilChanged((x, y) => x.id === y.id))
      .subscribe(products => {
        this.productData = products
        this.updateList()
      })

    this.tdsService.castTds.subscribe(tds => {
      this.tds = tds || []
      this.updateList()
    })

    this.productService.castProductsError.subscribe(error => this.productsError = error)
    this.tdsService.castTdsError.subscribe(error => this.tdsError = error)
  }

  private updateList = () => setTimeout(() => {
    if (!this.productData || !this.tds) return

    const data = this.productData.map(p => {
      const tds = find(this.tds, { id: p.tds_id }) || {}
      return { ...p, tds, tds_reference: tds.reference }
    })

    this.products.paginator = this.paginator
    this.products.sort = this.sort
    this.products.data = data
  })


  editProduct = (product) => {
    this.modalService.createEditProductModal(product)
  }

  showProduct = (product) => {
    this.modalService.createProductDetailModal(product, false)
  }

  downloadTdsAsPdf = async (product) => {
    if (!product.id) return
    this.tdsService.getTdsAsPdf(product.id)
      .then(this.getUrlFromBuffer)
      .then(url => window.open(url))
  }

  private getUrlFromBuffer = async (obj) => {
    const file = new Blob([obj], { type: 'application/pdf' })
    return URL.createObjectURL(file)
  }
}
