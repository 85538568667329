import { Injectable, OnDestroy } from '@angular/core'
import { BicobelHttpService } from 'src/app/api/bicobelHttp.service'
import { BehaviorSubject } from 'rxjs'
import { AuthenticationService } from './authentication.service'

@Injectable({
  providedIn: 'root',
})
export class ConservationService implements OnDestroy {

  private userSubscription

  private conservations = new BehaviorSubject(undefined)
  castConservations = this.conservations.asObservable()

  private conservationsError = new BehaviorSubject(undefined)
  castConservationsError = this.conservationsError.asObservable()

  constructor(
    private bicobelHttpService: BicobelHttpService,
    private authenticationService: AuthenticationService
  ) {
    this.init()
  }

  init = () => {
    this.userSubscription = this.authenticationService.castUser.subscribe(() => {
      this.reset()
      this.authenticationService.hasAccessToResource('conservation') && this.fetchConservations()
    })
  }

  ngOnDestroy(){
    this.userSubscription.unsubscribe()
  }

  reset = () => {
    this.conservations.next(undefined)
    this.conservationsError.next(undefined)
  }

  fetchConservations = () => {
    return this.bicobelHttpService.get('/conservation')
      .then(conservations => {
        this.conservations.next(Object.values(conservations))
        this.conservationsError.next(undefined)
        return conservations
      })
      .catch(error => {
        this.conservationsError.next(error)
      })
  }

  getConservations = () => {
    return this.conservations.value
  }

  addConservation = (conservation) => {
    return this.bicobelHttpService.post('/conservation', conservation)
      .then(this.fetchConservations)
  }
}
