import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { BicobelHttpService } from 'src/app/api/bicobelHttp.service'
import { MessageService } from 'src/app/services/message.service'
import { AuthenticationService } from 'src/app/services/authentication.service'

@Component({
    selector: 'changePassword',
    templateUrl: './changePassword.component.html',
    styleUrls: ['./changePassword.component.scss']
})
export class ChangePasswordComponent {

    passwordNew
    passwordRepeat
    passwordOld
    resetToken
    errorMessage
    sub

    constructor(
        private route: ActivatedRoute,
        private bicobelHttpService: BicobelHttpService,
        private messageService: MessageService,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        this.sub = this.route.queryParams.subscribe(params => {
            if (!params['token'] && !this.authenticationService.isLoggedIn()) {
                return this.redirectHome()
            }
            this.resetToken = params['token']
        })
    }

    redirectHome = () => {
        this.router.navigate(['home'])
    }

    resetPassword = () => {
        if (!this.checkResetRequirements()) return
        this.messageService.createConfirmationDialog('CHANGE_PASSWORD.CONFIRM_SAVE_PASSWORD_TITLE', 'CHANGE_PASSWORD.CONFIRM_SAVE_PASSWORD').subscribe(agrees => {
            if (!agrees) return

            if (this.resetToken) {
                resetWithToken()
            } else {
                resetWithOldPassword()
            }
        })

        const resetWithToken = () => {
            this.bicobelHttpService.post(`/resetpassword`, { token: this.resetToken, newpassword: this.passwordNew }, { security: false })
                .then(succes)
                .catch(err => this.errorMessage = err)
        }

        const resetWithOldPassword = () => {
            this.bicobelHttpService.post(`/changepassword`, { oldpassword: this.passwordOld, newpassword: this.passwordNew })
                .then(succes)
                .catch(err => this.errorMessage = err)
        }

        const succes = () => {
            this.messageService.new('CHANGE_PASSWORD.RESET_SUCCESS')
            this.redirectHome()
        }
    }

    checkResetRequirements = () => {
        if (!this.resetToken && (!this.passwordOld || this.passwordOld === '')) {
            this.errorMessage = "CHANGE_PASSWORD.NO_OLD_PASSWORD_PROVIDED"
            return false
        }
        if (!this.passwordNew || this.passwordNew === '' || this.passwordNew !== this.passwordRepeat) {
            this.errorMessage = "CHANGE_PASSWORD.NO_NEW_PASSWORD_PROVIDED"
            return false
        }
        return true
    }
}