import { Component } from '@angular/core'
import { StorageService } from 'src/app/services/storage.service'

@Component({
    selector: 'reminders',
    templateUrl: './reminders.component.html',
    styleUrls: ['./reminders.component.scss']
})
export class RemindersComponent {

    reminders

    constructor(private storageService: StorageService) {
        this.reminders = this.storageService.get('reminders') || []
    }

    addReminder = () => {
        this.reminders.push({
            text: '',
            height: 300,
            width: 300
        })
        this.save()
    }

    deleteReminder = (reminder, index) => {
        this.reminders.splice(index, 1)
        this.save()
    }

    onChange = ($event, r) => {
        r.height = $event.target.clientHeight
        r.width = $event.target.clientWidth
        r.text = $event.target.value
        this.save()
    }

    save = () => {
        this.storageService.store('reminders', this.reminders)
    }
}