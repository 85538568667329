import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { MessageService } from 'src/app/services/message.service'
import { ShelflifeService } from 'src/app/services/shelflife.service'

export interface DialogData {
    title: string
    text: string
}

@Component({
    selector: 'addShelflife-dialog',
    templateUrl: './addShelflife.component.html',
    styleUrls: ['./addShelflife.component.scss']
})
export class AddShelflifeModalComponent {

    shelflife = {
        code: null,
        name_nl: null,
        name_fr: null,
        name_en: null,
        name_de: null
    }
    formError
    loading = false

    addShelflifeError

    constructor(
        private messageService: MessageService,
        private shelflifeService: ShelflifeService,
        public dialogRef: MatDialogRef<AddShelflifeModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    closeDialog = () => this.dialogRef.close()

    addShelflife() {
        if (!this.checkMinimalRequirements()) return
        this.addShelflifeError = undefined
        this.messageService.createConfirmationDialog('Opslaan', 'Weet u zeker dat u deze houdbaarheid wilt toevoegen?').subscribe(agrees => {
            if (!agrees) return
            this.shelflifeService.addShelflife(this.shelflife)
                .then(res => {
                    this.closeDialog()
                }).catch(err => {
                    this.addShelflifeError = err
                })
        })
    }

    checkMinimalRequirements() {
        this.formError = undefined
        if (!this.shelflife.code || !this.shelflife.name_fr || !this.shelflife.name_nl || !this.shelflife.name_en || !this.shelflife.name_de) {
            this.formError = 'Niet alle velden zijn ingevuld'
            return false
        }
        return true
    }
}