import { Component } from '@angular/core'
import { LanguageService } from 'src/app/services/language.service';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    constructor(private languageService: LanguageService) { }

    setLanguage = (lang) => {
        this.languageService.setLanguage(lang)
    }

    getLanguageActive = (lang) => {
        return this.languageService.getLanguage() === lang
            ? 'active'
            : ''
    }

    getLanguage = () => {
        return this.languageService.getLanguage()
    }
}