import { Injectable, OnDestroy } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { find, map, isEmpty, padStart } from 'lodash'
import { BicobelHttpService } from 'src/app/api/bicobelHttp.service'
import { AuthenticationService } from './authentication.service'

@Injectable({
  providedIn: 'root',
})

export class LabelService implements OnDestroy {

  private userSubscription

  private labels = new BehaviorSubject(undefined)
  castLabels = this.labels.asObservable()

  private labelsError = new BehaviorSubject(undefined)
  castLabelsError = this.labelsError.asObservable()

  constructor(
    private bicobelHttpService: BicobelHttpService,
    private authenticationService: AuthenticationService
  ) {
    this.init()
  }

  init = () => {
    this.userSubscription = this.authenticationService.castUser.subscribe(() => {
      this.reset()
      this.authenticationService.hasAccessToResource('label') && this.fetchLabels()
    })
  }

  ngOnDestroy(){
    this.userSubscription.unsubscribe()
  }

  reset = () => {
    this.labels.next(undefined)
    this.labelsError.next(undefined)
  }

  fetchLabels = () => {
    return this.bicobelHttpService.get('/label')
      .then(labels => map(labels, ({ barcode, can_print, ...rest }) => ({
        barcode: padStart(barcode, 8, '0'),
        can_print: can_print === '1' || can_print === true,
        ...rest
      })))
      .then(labels => {
        this.labels.next(labels)
        this.labelsError.next(undefined)
      })
      .catch(err => {
        this.labelsError.next(err)
      })
  }

  saveNewLabel = async (newLabel) => {
    return this.bicobelHttpService.post('/label', newLabel)
      .then(this.fetchLabels)
  }

  saveEditedLabel = async (editedLabel) => {
    return this.bicobelHttpService.put('/label', editedLabel)
      .then(this.fetchLabels)
  }

  flipLabelPrintable = (label) => {
    return this.saveEditedLabel({ ...label, can_print: !label.can_print })
  }

  getLabelById = (id) => {
    return find(this.labels.value, label => Number(label.id) === Number(id))
  }

  deleteLabelById = (id) => {
    return this.bicobelHttpService.delete(`/label/${id}`)
      .then(this.fetchLabels)
  }

  getExamplePdf = () => {
    return this.bicobelHttpService.get(`/labelpdf`, { responseType: 'arraybuffer' })
  }

  getPdf = (labelid, expirationDate, lotnumber) => {
    if (isEmpty(expirationDate)) expirationDate = ' '
    if (isEmpty(lotnumber)) lotnumber = ' '

    const encodedExpirationDate = encodeURIComponent(expirationDate.replace(/\//gi, '-'))
    const encodedLotnumber = encodeURIComponent(lotnumber.replace(/\//gi, '-'))
    return this.bicobelHttpService.get(`/labelpdf/${labelid}/${encodedExpirationDate}/${encodedLotnumber}`, { responseType: 'arraybuffer' })
  }
}
