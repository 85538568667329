import { Component, Inject, ViewChild, ElementRef } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { ProductService } from 'src/app/services/product.service'
import { MessageService } from 'src/app/services/message.service';
import { TdsService } from 'src/app/services/tds.service';

export interface DialogData {
    product: object
}

@Component({
    selector: 'editProduct-dialog',
    templateUrl: './editProduct.component.html',
    styleUrls: ['./editProduct.component.scss']
})
export class EditProductModalComponent {
    @ViewChild('input1') inputEl: ElementRef;

    product
    errorSaveProduct

    loading = false
    tds
    filter
    selectedTds

    constructor(
        private messageService: MessageService,
        private tdsService: TdsService,
        private productService: ProductService,
        public dialogRef: MatDialogRef<EditProductModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        this.product = { ...data.product }
        this.tdsService.castTds.subscribe(tds => this.tds = tds)
    }

    closeDialog = (res) => this.dialogRef.close(res)

    saveProduct = () => {
        this.messageService.createConfirmationDialog('save product', 'Bent u zeker dat u de wijzigingen aan dit product wilt opslaan?').subscribe(agrees => {
            if (!agrees) return
            this.productService.saveProduct(this.product.id, this.selectedTds.id).then(res => {
                this.closeDialog(res)
            }).catch(err => {
                this.errorSaveProduct = err
            })
        })
    }

    opened = () => setTimeout(() => {
        this.inputEl.nativeElement.focus()
    })
}