import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core'
import { BasketService } from '../../services/basket.service'
import { ProductService } from '../../services/product.service'
import { LanguageService } from '../../services/language.service'
import { BicobelHttpService } from 'src/app/api/bicobelHttp.service'
import { ModalService } from '../../services/modal.service'
import { GenericObjectFilterPipe } from '../../filters/genericObjectFilter.pipe'
import { AuthenticationService } from 'src/app/services/authentication.service'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { Router } from '@angular/router'
import { map, trim } from 'lodash'
import { fromEvent } from 'rxjs'

@Component({
    selector: 'shop',
    templateUrl: './shop.component.html',
    styleUrls: ['./shop.component.scss'],
})

export class ShopComponent implements OnInit, OnDestroy {

    @ViewChild('filterEl') filterEl: ElementRef
    filter = ''

    products
    filteredProducts
    visibleProducts

    viewType = 'tile'

    productsError
    productSubscription
    productErrorSubscription

    constructor(
        private basketService: BasketService,
        private productService: ProductService,
        private router: Router,
        private modalService: ModalService,
        private bicobelHttpService: BicobelHttpService,
        private pipe: GenericObjectFilterPipe,
        private authenticationService: AuthenticationService
    ) {
        if (!this.isLoggedIn()) {
            this.modalService.createLoginDialog('Om gebruik te maken van de webwinkel moet u eerst inloggen.')
        }
    }

    ngOnInit() {
        this.productErrorSubscription = this.productService.castProductsError.subscribe(error => this.productsError = error)
        this.productSubscription = this.productService.castProducts.subscribe(products => {
            // lodash map always return array, even with products === undefined
            this.products = map(products, (p, i) => {
                const inBasket = this.inBasket(p)
                const internalImageUrl = '../../../assets/images/bokkepoot.jpg'
                return { ...p, internalImageUrl, orderAmount: inBasket > 0 ? inBasket : 1 }
            })
            this.update()
        })

        fromEvent(this.filterEl.nativeElement, 'keyup')
            .pipe(debounceTime(500), distinctUntilChanged())
            .subscribe(event => {
                this.filter = event['target'].value
                this.update()
            })
    }

    ngOnDestroy() {
        this.productSubscription.unsubscribe()
        this.productErrorSubscription.unsubscribe()
    }

    // triggers on filter change
    update = () => {
        this.filteredProducts = this.pipe.transform(this.products, this.filter,
            ['description_nl', 'description_fr', 'description_en', 'description_de', 'bicobel_reference'])
        this.visibleProducts = this.filteredProducts.slice(0, 12)
        this.visibleProducts.forEach(p => this.fetchImageFromServer(p))
    }

    // triggers when card enters in viewport, triggers every time it enters
    onScopeEnter = (product, index) => {
        const visible = this.visibleProducts.length
        if (index > visible - 6) {
            this.filteredProducts.slice(visible, visible + 6).forEach(p => {
                this.fetchImageFromServer(p)
                this.visibleProducts.push(p)
            })
        }
    }

    private fetchImageFromServer = (product) => {
        if (product.imageReceived || !product.image_reference) return
        this.bicobelHttpService.get(`/image/product/${trim(product.image_reference)}`, { responseType: 'blob' as 'json' })
            .then(imageBlob => {
                const url = URL.createObjectURL(imageBlob)
                product.internalImageUrl = url
                product.imageReceived = true
            })
    }

    addProductToBasket = (product) => {
        this.basketService.addProduct(product)
    }

    removeProductFromBasket = (product) => {
        this.basketService.deleteProduct(product)
    }

    inBasket = (product) => {
        return this.basketService.inBasket(product)
    }

    addOneToProduct = (product) => {
        product.orderAmount++
    }

    removeOneFromProduct = (product) => {
        if (product.orderAmount > 1)
            product.orderAmount--
    }

    redirectToBasket = () => {
        this.router.navigate(['/basket'])
    }


    changeViewType = (type) => {
        if (this.viewType === type) return
        this.viewType = type
        this.update()
    }

    showProductDetails = (p) => {
        this.modalService.createProductDetailModal(p)
    }

    getProductDescription = this.productService.getProductDescription

    isLoggedIn = () => {
        return this.authenticationService.isLoggedIn()
    }

    scroll = (el: HTMLElement) => {
        el.scrollIntoView()
    }
    trackByFn = (i, v) => v.product_id // Very required function for performance
}