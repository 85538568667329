import { Component, ViewChild } from '@angular/core'
import { AccountService } from 'src/app/services/account.service'
import { ModalService } from 'src/app/services/modal.service'
import { MessageService } from 'src/app/services/message.service'
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material'
import { Role } from '../../interfaces/interfaces'

@Component({
    selector: 'roleList',
    templateUrl: './roleList.component.html',
    styleUrls: ['./roleList.component.scss']
})
export class RolesListComponent {
    @ViewChild(MatPaginator) paginator: MatPaginator
    @ViewChild(MatSort) sort: MatSort

    roles: MatTableDataSource<Role> = new MatTableDataSource([])
    rolesError

    deleteRoleError

    constructor(private accountService: AccountService, private modalService: ModalService, private messageService: MessageService) {
        this.initSubscriptions()
    }

    private initSubscriptions = () => {
        this.accountService.castRoles.subscribe(this.setRoles)
        this.accountService.castAccountsError.subscribe(error => this.rolesError = error)
    }

    private setRoles = (roles) => setTimeout(() => {
        this.roles.paginator = this.paginator
        this.roles.sort = this.sort
        this.roles.data = roles || []
    })

    addRole = () => {
        this.modalService.createAddRoleModal()
    }

    deleteRoleByName = (name) => {
        this.messageService.createConfirmationDialog('Rol verwijderen', 'Bent u zeker dat u deze rol wilt verwijderen?').subscribe(res => {
            if (res) this.accountService.deleteRoleByName(name)
                .then(res => this.deleteRoleError = undefined)
                .catch(err => this.deleteRoleError = err)
        })
    }

    applyFilter = (filterValue: string) => {
        this.roles.filter = filterValue.trim().toLowerCase()
        if (this.roles.paginator) {
            this.roles.paginator.firstPage()
        }
    }
}