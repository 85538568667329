import { Pipe, PipeTransform } from '@angular/core'
import { reduce, mapValues, values } from 'lodash'

export interface Paginator {
    length: any,
    pageIndex: any,
    pageSize: any,
    previousPageIndex: any
}

@Pipe({
    name: 'pagination'
})

export class PaginationFilter implements PipeTransform {

    transform(items: any[], paginator: Paginator): any[] {
        if (!items) return []

        const {pageIndex, pageSize} = paginator

        const start = pageIndex * pageSize
        const stop = start + pageSize

        return items.slice(start, stop)
    }
}