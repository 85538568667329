import { Pipe, PipeTransform } from '@angular/core'
import { LanguageService } from '../services/language.service'
import { get } from 'lodash'

@Pipe({
    name: 'translate',
    pure: false
})

/**
 * EXAMPLE USAGE: 
 * 
 * 'STRING.TO_BE.TRANSLATED| not to be translated |OTHER_STRING.TO_BE.TRANSLATED'
 */
export class TranslatePipe implements PipeTransform {
    constructor(private languageService: LanguageService) { }
    transform(key): any {
        if (!key) return ''
        //Split input into different parts to be translated
        const parts = key.split('|')

        //translate each individual part
        const translatedParts = parts.map(part => {
            //find translation path in resource bundles (path split by .)
            const path = part.trim().toUpperCase().split('.')
            const translated = get(this.languageService.data, path)
            //if found return translation, else return the part itself
            return translated || part;
        })

        //join the translated (or not translated) parts together with a space in between and return.
        return translatedParts.join(' ')
    }
}