import jwtDecode from 'jwt-decode'

export const tokenValid = (token) => {
    try {
        const object = jwtDecode(token)
        if ((object.exp * 1000) < (Date.now() - 100)) {
            return false
        }
        return true
    } catch (err) {
        return false
    }
}

export const getUserFromToken = (token) => {
    if (!token) return undefined
    try {
        return jwtDecode(token).account
    } catch (err) {
        return undefined
    }
}