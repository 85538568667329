import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
// import * as Sentry from "@sentry/browser"

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

import 'hammerjs'

if (environment.production) {
  enableProdMode()
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err))

// Sentry.init({
//   dsn: "https://1294896336fe465eb5e749505a549eea@sentry.io/1502635"
// })
