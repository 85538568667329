import { Injectable, OnDestroy } from '@angular/core'
import { MessageService } from './message.service'
import { AuthenticationService } from './authentication.service'
import { BicobelHttpService } from 'src/app/api/bicobelHttp.service'
import { BehaviorSubject } from 'rxjs'
import { find, some } from 'lodash'

@Injectable({
  providedIn: 'root',
})
export class ClientService implements OnDestroy {

  private userSubscription

  private clients = new BehaviorSubject(undefined)
  castClients = this.clients.asObservable()

  private clientsError = new BehaviorSubject(undefined)
  castClientsError = this.clientsError.asObservable()

  private clientDeliveryAddress = new BehaviorSubject(undefined)
  castClientDeliveryAddress = this.clientDeliveryAddress.asObservable()

  private clientDeliveryAddressError = new BehaviorSubject(undefined)
  castClientDeliveryAddressError = this.clientDeliveryAddressError.asObservable()

  constructor(
    private messageService: MessageService,
    private bicobelHttpService: BicobelHttpService,
    private authenticationService: AuthenticationService
  ) {
    this.init()
  }

  init = () => {
    this.userSubscription = this.authenticationService.castUser.subscribe(() => {
      this.reset()
      this.authenticationService.hasAccessToResource('client') && this.fetchClients()
      this.authenticationService.hasAccessToResource('deliveryAddress') && this.fetchDeliveryAddress()
    })
  }

  ngOnDestroy(){
    this.userSubscription.unsubscribe()
  }

  reset = () => {
    this.clients.next(undefined)
    this.clientsError.next(undefined)
    this.clientDeliveryAddress.next(undefined)
    this.clientDeliveryAddressError.next(undefined)
  }

  fetchClients = () => {
    return this.bicobelHttpService.get('/client')
      .then(clients => {
        this.clients.next(Object.values(clients))
        this.clientsError.next(undefined)
        return clients
      })
      .catch(error => {
        this.clientsError.next(error)
      })
  }

  fetchDeliveryAddress = () => {
    const clientId = this.authenticationService.getClientId()

    if (!clientId) {
      this.clientDeliveryAddressError.next('This account has no client_id')
      this.messageService.debug('Resource delivery address not allowed(no client_id)')
      throw new Error('User doesn\'t have a clientId, hence can not fetch delivery addresses')
    }

    return this.bicobelHttpService.get(`/client/me/address`)
      .then(addresses => {
        this.clientDeliveryAddress.next(Object.values(addresses))
        return addresses
      })
      .catch(error => {
        this.clientDeliveryAddressError.next(error)
        throw new Error(error)
      })
  }

  getClientExistsById = (id: Number) => {
    return some(this.clients.value, client => Number(client.id) === Number(id))
  }

  getClientById = (id: Number) => {
    return find(this.clients.value, client => Number(client.id) === Number(id))
  }
}
