import { Component, Input, Output, ViewChild, ElementRef } from '@angular/core'
import { EventEmitter } from '@angular/core'

@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {
  @ViewChild('input1') inputEl: ElementRef

  @Output('onFilter') emitter: EventEmitter<String> = new EventEmitter<String>()

  @Input('title') set setInitoTitleValue(title) {
    if (title) this.title = title
  }

  filter
  title

  notify = () => {
    this.emitter.emit(this.filter)
  }
}
