import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { MessageService } from './message.service'
import { publicResources, getAllowedResources, getAllowedRegions } from '../../lib/access'
import { BehaviorSubject } from 'rxjs'
import { BicobelHttpService } from 'src/app/api/bicobelHttp.service'
import { getUserFromToken } from '../../lib/jwt'
import { isEqual, get, includes } from 'lodash'
import { distinctUntilChanged, map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})

export class AuthenticationService {
  private user = new BehaviorSubject(undefined)
  castUser = this.user.asObservable()

  private lockedNavigation = false

  constructor(private router: Router, private messageService: MessageService, private bicobelHttpService: BicobelHttpService) {
    this.subscribeToToken()
  }

  private subscribeToToken = () => {
    this.bicobelHttpService.castToken
      .pipe(
        map(token => getUserFromToken(token)),
        distinctUntilChanged(isEqual),
      )
      .subscribe(res => this.user.next(res))
  }

  forceRedirectHome = () => {
    this.unlockNavigation()
    this.router.navigate(['/home'])
  }

  login = async (username, password) => {
    return this.bicobelHttpService.post('/login', { username, password }, { security: false })
      .then(user => {
        const username = get(user, 'username')
        this.messageService.new(`MESSAGE.LOGIN_SUCCESS | ${username} ! `, 'neutral', 5)
        return user
      })
  }

  /**
   * usage precautions: /logout to server returns token-error when token expired.
   * Resolve or reject, either way this function should hard-remove the token and userobject to prevent recursion.
   */
  logout = () => {
    this.user.next(undefined)
    this.bicobelHttpService.logout()
    this.forceRedirectHome()
  }

  /**
   * Navigation while locked requests user's approval.
   * Usage: Add canDeactivate guard in app.module.ts routing
   */
  lockNavigation = () => {
    this.lockedNavigation = true
  }

  unlockNavigation = () => {
    this.lockedNavigation = false
  }

  isNavigiationLocked = () => {
    return this.lockedNavigation
  }

  /**
   * returns true if user is allowed in region.
   * triggered by router.navigate()
   * Usage: add canActivate guard in app.module.ts routing
   */
  canActivate = (next, state) => {
    // no user => no access
    if (!this.user.value) {
      this.messageService.new('ERROR.INTERNAL.NOT_LOGGED_IN', 'danger', 10)
      return false
    }
    // if has access to path, allow requested path
    const destination = state.url.split('?')[0]

    if (this.hasAccessToPath(destination)) {
      return true
    }

    // User does not have access to requested path
    this.messageService.new('ERROR.INTERNAL.NOT_ALLOWED_REGION', 'danger', 10)
    this.messageService.debug(state.url + ' not allowed.')
    return false
  }

  canDeactivate = () => new Promise((resolve) => {
    if (!this.lockedNavigation) {
      resolve(true)
      return
    }
    this.messageService.createConfirmationDialog('MESSAGE.DEACTIVATE_TITLE', 'MESSAGE.DEACTIVATE')
      .subscribe(agrees => {
        if (agrees) this.unlockNavigation()
        resolve(!!agrees)
      })
  })

  impersonate = async (userId) => {
    this.bicobelHttpService.cancelAllCurrentHttpCalls()
    return this.bicobelHttpService.get(`/impersonate/${Number(userId)}`)
      .then(user => {
        const username = get(user, 'username')
        this.messageService.new('Impersonating user: ' + username)

        this.router.navigate(['/home'])
      })
  }

  unimpersonate = async () => {
    this.bicobelHttpService.cancelAllCurrentHttpCalls()
    return this.bicobelHttpService.get(`/unimpersonate`)
      .then(user => {
        const username = get(user, 'username')
        this.messageService.new('Unimpersonated. Continuing as ' + username)

        this.unlockNavigation()
        this.router.navigate(['/admin/account'])

        return user
      })
  }

  hasAccessToPath = (region) => {
    const roles = get(this.user.value, 'roles', [])
    const allowedRegions = getAllowedRegions(roles)

    return (
      includes(allowedRegions, region) ||
      includes(allowedRegions, '*')
    )
  }

  hasAccessToResource = (resource) => {
    const resourceIsPublic = includes(publicResources, resource)
    const roles = get(this.user.value, 'roles', [])
    const clientId = get(this.user.value, 'client_id')
    const clientIdRequired = includes(['label', 'deliveryAddress'], resource)

    if (resourceIsPublic) return true
    if (clientIdRequired && !clientId) return false

    const allowedResources = getAllowedResources(roles)

    return (
      includes(allowedResources, resource) ||
      includes(allowedResources, '*')
    )
  }

  hasRole = (role) => {
    const roles = get(this.user.value, 'roles', [])
    return includes(roles, role)
  }

  getUserId = () => {
    return get(this.user.value, 'id')
  }

  getUser = () => {
    return this.user.value
  }

  getUsername = () => {
    return get(this.user.value, 'username')
  }

  getClientId = () => {
    return get(this.user.value, 'client_id')
  }

  isLoggedIn = () => {
    return !!this.user.value
  }
}
