import { Pipe, PipeTransform } from '@angular/core'
import { reduce, mapValues, values, isEmpty } from 'lodash'

@Pipe({
    name: 'filterByObject'
})

export class GenericObjectFilterPipe implements PipeTransform {
    transform(items: any[], searchText: string, keysToCheck: Array<string>): any[] {
        if (!items) return []
        if (!searchText || searchText.trim() === '') return items
        if (!keysToCheck) throw Error('FilterByOject error: No valid array of fields to check against provided')

        //array of keywords
        const keywords = searchText.trim().toLowerCase().split(' ')

        //items filtered on searchText
        const filteredItems = items.filter(it => {
            const result = reduce(keywords, (result, value, key) => {
                //map every field to a boolean (true is included, false is doesn't include)
                const objectMappedToBoolean = mapValues(it, (fieldValue, fieldKey) => {
                    if (!keysToCheck.includes(fieldKey)) return false
                    if (!fieldValue) return false
                    return String(fieldValue.toLowerCase()).includes(value)
                })

                //add one to result if a field has value 'true'
                if (values(objectMappedToBoolean).some(o => o === true)) {
                    result.succesful++
                }
                return result
            }, { succesful: 0, total: keywords.length })

            // return true if all keywords are found
            return result.succesful === result.total
        })

        return filteredItems
    }
}