import { Injectable, OnDestroy } from '@angular/core'
import { BicobelHttpService } from 'src/app/api/bicobelHttp.service'
import { AuthenticationService } from './authentication.service'
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CountryService implements OnDestroy {

  private userSubscription

  private countries = new BehaviorSubject(undefined)
  castCountries = this.countries.asObservable()

  private countriesError = new BehaviorSubject(undefined)
  castCountriesError = this.countriesError.asObservable()

  constructor(
    private bicobelHttpService: BicobelHttpService,
    private authenticationService: AuthenticationService
  ) {
    this.init()
  }

  init = () => {
    this.userSubscription = this.authenticationService.castUser.subscribe(() => {
      this.reset()
      this.authenticationService.hasAccessToResource('country') && this.fetchCountries()
    })
  }

  ngOnDestroy(){
    this.userSubscription.unsubscribe()
  }

  reset = () => {
    this.countries.next(undefined)
    this.countriesError.next(undefined)
  }

  fetchCountries = () => {
    return this.bicobelHttpService.get('/country')
      .then(countries => {
        this.countries.next(Object.values(countries))
        this.countriesError.next(undefined)
        return countries
      })
      .catch(error => {
        this.countriesError.next(error)
        throw error
      })
  }

  getCountries = () => {
    return this.countries.value
  }

  addCountry = (country) => {
    return this.bicobelHttpService.post('/country', country)
      .then(this.fetchCountries)
  }
}
