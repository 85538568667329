import { Component } from '@angular/core'
import { BicobelHttpService } from 'src/app/api/bicobelHttp.service'
import { MessageService } from 'src/app/services/message.service'
import { MatDialogRef } from '@angular/material'

@Component({
    selector: 'resetPassword-dialog',
    templateUrl: './resetPassword.component.html',
    styleUrls: ['./resetPassword.component.scss']
})
export class ResetPasswordModalComponent {

    username
    errorSendingMail

    constructor(
        public dialogRef: MatDialogRef<ResetPasswordModalComponent>,
        private bicobelHttpService: BicobelHttpService,
        private messageService: MessageService
    ) { }

    sendMail = () => this.messageService.createConfirmationDialog('MODAL.RESET_PASSWORD.CONFIRMATION_TITLE', `MODAL.RESET_PASSWORD.CONFIRMATION_CONTENT| ${this.username}?`).subscribe(agrees => {
        if (!agrees) return
        this.errorSendingMail = undefined
        this.bicobelHttpService.get(`/resetpassword/${this.username}`, { security: false })
            .then(() => {
                this.messageService.new(`Een email is verzonder naar ${this.username} om uw wachtwoord opnieuw in te stellen.`)
                this.closeDialog()
            }).catch(err => {
                this.messageService.new(err, 'danger')
                this.errorSendingMail = err
            })
    })

    closeDialog = () => this.dialogRef.close()
}