import { Component } from '@angular/core'
import { BasketService } from '../../services/basket.service'
import { ProductService } from '../../services/product.service'
import { MessageService } from '../../services/message.service'
import { AuthenticationService } from '../../services/authentication.service'
import { ModalService } from 'src/app/services/modal.service'
import { LanguageService } from 'src/app/services/language.service'
import { BicobelHttpService } from 'src/app/api/bicobelHttp.service'
import { Router } from '@angular/router'
import { Order } from '../../interfaces/interfaces'
import { sumBy, trim } from 'lodash'
import { map } from 'rxjs/operators'

@Component({
    selector: 'basket',
    templateUrl: './basket.component.html',
    styleUrls: ['./basket.component.scss'],
})

export class BasketComponent {

    user

    originalOrder
    originalProducts

    order: Order = {
        deliveryDate: undefined,
        deliveryAddressId: undefined,
        remarks: undefined,
        products: []
    }

    errorPlacingOrder

    constructor(private languageService: LanguageService,
        private modalService: ModalService,
        private basketService: BasketService,
        private productService: ProductService,
        private messageService: MessageService,
        private authenticationService: AuthenticationService,
        private bicobelHttpService: BicobelHttpService,
        private router: Router
    ) {
        this.initSubscriptions()
    }

    private initSubscriptions = () => {
        this.authenticationService.castUser.subscribe(user => this.user = user)
        this.basketService.castOrder.subscribe(order => {
            this.originalOrder = order
            this.mapOrder()
        })
        this.productService.castProducts.subscribe(products => {
            this.originalProducts = products
            this.mapOrder()
        })
    }

    private mapOrder = () => {
        if (!this.originalOrder || !this.originalProducts) return
        const products = this.originalOrder.products.map(pr => {
            return {
                ...this.productService.getProductById(pr.id),
                orderAmount: pr.orderAmount,
            }
        })
        products.forEach(this.fetchImageFromServer)
        this.order = { ...this.originalOrder, products }
    }

    private fetchImageFromServer = (product) => {
        if (product.imageReceived || !product.image_reference) return
        this.bicobelHttpService.get(`/image/product/${trim(product.image_reference)}`, { responseType: 'blob' as 'json' })
            .then(imageBlob => {
                const url = URL.createObjectURL(imageBlob)
                product.internalImageUrl = url
                product.imageReceived = true
            })
    }

    getProductDescription = (product) => {
        const lang = this.languageService.getLanguage()
        return product['description_' + (lang || 'nl')]
    }

    placeOrder = () => {
        this.modalService.createOrderModal().subscribe(result => {
            if (!result) return
            this.basketService.placeOrder(result.deliveryDate, result.remarks, result.deliveryAddressId)
                .then(res => this.errorPlacingOrder = undefined)
                .catch(err => this.errorPlacingOrder = err)
        })
    }

    addProduct = (product) => {
        this.basketService.addProduct(product)
    }

    removeOneFromProduct = (product) => {
        this.basketService.removeOneFromProduct(product)
    }

    deleteProduct = (product) => {
        this.messageService.createConfirmationDialog('BASKET.DELETE', 'BASKET.DELETE_PRODUCT_CONFIRM').subscribe(agrees => {
            if (agrees) this.basketService.deleteProduct(product)
        })
    }

    orderIsEmpty = () => {
        return this.order.products.length === 0
    }

    redirectToProducts = () => {
        this.router.navigate(['/shop'])
    }

    deleteAllProducts = () => {
        this.messageService.createConfirmationDialog('BASKET.DELETE', 'BASKET.DELETE_ALL_PRODUCT_CONFIRM').subscribe(agrees => {
            if (agrees) this.basketService.deleteAllProducts()
        })
    }
}