import { Component, ViewChild } from '@angular/core'
import { CountryService } from 'src/app/services/country.service'
import { ModalService } from 'src/app/services/modal.service'
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Country } from 'src/app/interfaces/interfaces';

@Component({
    selector: 'countryList',
    templateUrl: './countryList.component.html',
    styleUrls: ['./countryList.component.scss']
})
export class CountryListComponent {
    @ViewChild(MatPaginator) paginator: MatPaginator
    @ViewChild(MatSort) sort: MatSort

    countries: MatTableDataSource<Country> = new MatTableDataSource([])
    countriesError

    constructor(private countryService: CountryService, private modalSevice: ModalService) {
        this.initSubscriptions()
    }

    private initSubscriptions = () => {
        this.countryService.castCountries.subscribe(this.setCountries)
        this.countryService.castCountriesError.subscribe(error => this.countriesError = error)
    }

    private setCountries = (countries) => setTimeout(() => {
        this.countries.paginator = this.paginator
        this.countries.sort = this.sort
        this.countries.data = countries || []
    })

    createNewCountry = () => {
        this.modalSevice.createAddCountryModal()
    }

    applyFilter = (filterValue: string) => {
        this.countries.filter = filterValue.trim().toLowerCase()
        if (this.countries.paginator)
            this.countries.paginator.firstPage()
    }
}