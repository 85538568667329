import { Component, ViewChild } from '@angular/core'
import { MessageService } from 'src/app/services/message.service'
import { TdsService } from 'src/app/services/tds.service'
import { Router } from '@angular/router'
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material'
import { Tds } from 'src/app/interfaces/interfaces'

@Component({
  selector: 'tdsList',
  templateUrl: './tdsList.component.html',
  styleUrls: ['./tdsList.component.scss']
})
export class TdsListComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort

  tds: MatTableDataSource<Tds> = new MatTableDataSource([])

  tdsError

  constructor(private tdsService: TdsService, private router: Router, private messageService: MessageService) {
    this.initSubscriptions()
  }

  private initSubscriptions = () => {
    this.tdsService.castTds.subscribe(this.setTds)
    this.tdsService.castTdsError.subscribe(error => this.tdsError = error)
  }

  private setTds = (tds) => setTimeout(() => {
    this.tds.paginator = this.paginator
    this.tds.sort = this.sort
    this.tds.data = tds || []
  })

  applyFilter = (filterValue: string) => {
    this.tds.filter = filterValue.trim().toLowerCase()
    if (this.tds.paginator)
      this.tds.paginator.firstPage()
  }

  editTdsById = (id) => {
    this.router.navigate(['admin/tds/edit'], { queryParams: { id: id } })
  }

  deleteTdsById = (id) => {
    this.messageService.createConfirmationDialog('TDS verwijderen', 'Bent u zeker dat u dit TDS wilt verwijderen?').subscribe(agrees => {
      if (!agrees) return
      this.tdsService.deleteById(id).catch(err => {
        this.tdsError = err
      })
    })
  }
}
