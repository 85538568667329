import { Injectable, OnDestroy } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { MessageService } from './message.service'
import { StorageService } from './storage.service'
import { BicobelHttpService } from 'src/app/api/bicobelHttp.service'
import { AuthenticationService } from './authentication.service'
import { find, map, clamp, remove, get } from 'lodash'
import * as moment from 'moment'

@Injectable({
  providedIn: 'root',
})

export class BasketService implements OnDestroy {

  private userSubscription

  private order = new BehaviorSubject({ products: [] })
  castOrder = this.order.asObservable()

  private orderError = new BehaviorSubject(undefined)
  castOrderError = this.orderError.asObservable()

  private orderHistory = new BehaviorSubject(undefined)
  castOrderHistory = this.orderHistory.asObservable()

  private orderHistoryError = new BehaviorSubject(undefined)
  castOrderHistoryError = this.orderHistoryError.asObservable()

  constructor(
    private messageService: MessageService,
    private storageService: StorageService,
    private bicobelHttpService: BicobelHttpService,
    private authenticationService: AuthenticationService
  ) {
    this.init()
  }

  init = () => {
    this.userSubscription = this.authenticationService.castUser.subscribe(() => {
      this.reset()
      this.authenticationService.hasAccessToResource('order') && this.fetchOrderHistory()
      this.authenticationService.hasAccessToResource('orderHistory') && this.getOrderFromStorage()
    })
  }

  ngOnDestroy(){
    this.userSubscription.unsubscribe()
  }

  reset = () => {
    this.order.next({ products: [] })
    this.orderError.next(undefined)
    this.orderHistory.next(undefined)
    this.orderHistoryError.next(undefined)
  }

  fetchOrderHistory = async () => {
    return this.bicobelHttpService.get('/order')
      .then(orderHistory => {
        this.orderHistory.next(Object.values(orderHistory))
        this.orderHistoryError.next(undefined)
        return orderHistory
      })
      .catch(error => {
        this.orderHistoryError.next(error)
        throw error
      })
  }

  private putInStorage = () => {
    const userId = this.authenticationService.getUserId()
    if (!userId) return
    this.storageService.store('basket_' + userId, this.order.value)
  }

  getOrderFromStorage = async () => {
    const order = this.storageService.get('basket_' + this.authenticationService.getUserId())

    order
      ? this.order.next(order)
      : this.order.next({ products: [] })

    return this.order.value
  }

  getOrder = () => {
    return this.order.value
  }

  placeOrder = (deliveryDate, remarks, deliveryAddressId) => {
    const order = {
      orderlines: map(get(this.order.value, 'products'), p => ({
        product_id: p.id,
        amount: p.orderAmount
      })),
      delivery_date: moment(deliveryDate).format('YYYY-MM-DD'),
      remarks,
      delivery_address_id: deliveryAddressId
    }

    return this.bicobelHttpService.post('/order', order)
      .then(res => {
        this.messageService.new(`MODAL.ORDER.PLACED_MESSAGE| ${this.order['remarks']}`, 'success', 10)
        this.order.next({ products: [] })
        this.fetchOrderHistory()
        this.putInStorage()
        return res
      })
  }

  addProduct = (product) => {
    const copy = [...this.order.value.products]
    const found = find(copy, { id: Number(product.id) })

    const { id, orderAmount = 1 } = product

    found
      ? found.orderAmount++
      : copy.push({ id: Number(id), orderAmount })

    this.order.next({ ...this.order.value, products: copy })
    this.putInStorage()
  }

  removeOneFromProduct = (product) => {
    const copy = [...this.order.value.products]

    const found = find(copy, { id: Number(product.id) })
    found && clamp(--found.orderAmount, 0)

    this.order.next({ ...this.order.value, products: copy })
    this.putInStorage()
  }

  deleteProduct = (product) => {
    const copy = [...this.order.value.products]
    remove(copy, { id: Number(product.id) })

    this.order.next({ ...this.order.value, products: copy })
    this.putInStorage()
  }

  inBasket = (product) => {
    const found = find(this.order.value.products, { id: Number(product.id) })
    return get(found, 'orderAmount', 0)
  }

  deleteAllProducts = () => {
    this.order.next({ ...this.order.value, products: [] })
    this.putInStorage()
  }
}