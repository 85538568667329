import { Component } from '@angular/core'
import { MessageService } from 'src/app/services/message.service'
import { TdsService } from 'src/app/services/tds.service'
import { CountryService } from 'src/app/services/country.service'
import { ConservationService } from 'src/app/services/conservation.service'
import { ShelflifeService } from 'src/app/services/shelflife.service'
import { ModalService } from 'src/app/services/modal.service'
import { Router } from '@angular/router'
import { technicalDataSheetCompleted, requiredFields } from '../../../../lib/tds'
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'tdsNew',
  templateUrl: './tdsNew.component.html',
  styleUrls: ['./tdsNew.component.scss']
})
export class TdsNewComponent {

  saveTdsError

  documentChecked

  allExpanded = true

  countries = []
  shelflives = []
  conservations = []

  technicalDataSheet = {
    reference: undefined,
    general: {
      country_id: undefined,
      shelflife_id: undefined,
      conservation_id: undefined,
    },
    type_description: {
      type_description_nl: undefined,
      type_description_fr: undefined,
      type_description_en: undefined,
      type_description_de: undefined,
    },
    ingredients: {
      ingredients_nl: undefined,
      ingredients_fr: undefined,
      ingredients_en: undefined,
      ingredients_de: undefined,
    },
    allergies: {
      gluten: undefined,
      crustacean: undefined,
      egg: undefined,
      fish: undefined,
      groundnut: undefined,
      soy: undefined,
      milk: undefined,
      nuts: undefined,
      selery: undefined,
      mustard: undefined,
      sulfite: undefined,
      lupine: undefined,
      sesame: undefined,
      molluscs: undefined,
    },
    nutritionalvalue: {
      energy_kj: undefined,
      energy_kcal: undefined,
      fats: undefined,
      fats_saturated: undefined,
      fibers: undefined,
      sugars: undefined,
      proteins: undefined,
      salts: undefined,
      moisture: undefined,
      dry_matter: undefined,
      ash: undefined,
      sodium: undefined,
      ph: undefined,
      aw: undefined,
      total_kion_number: undefined,
      yeast: undefined,
      fungi: undefined,
      enterobacteriaceae: undefined,
      coliforms: undefined,
      salmonella: undefined,
      listeria_monocytogenes: undefined,
    }
  }

  constructor(private tdsService: TdsService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private countryService: CountryService,
    private conservationService: ConservationService,
    private modalService: ModalService,
    private shelflifeService: ShelflifeService,
    private router: Router
  ) {
    this.initSubscriptions()
    this.authenticationService.lockNavigation()
  }

  private initSubscriptions = () => {
    this.countryService.castCountries.subscribe(countries => this.countries = countries)
    this.shelflifeService.castShelflives.subscribe(shelflives => this.shelflives = shelflives)
    this.conservationService.castConservations.subscribe(conservations => this.conservations = conservations)
  }

  isRequired = (section, key) => requiredFields[section][key] || false

  saveTDS = () => {
    this.documentChecked = technicalDataSheetCompleted(this.technicalDataSheet)
    if (!this.documentChecked['ok']) return

    const newTds = {
      reference: this.technicalDataSheet.reference,
      ...this.technicalDataSheet.general,
      ...this.technicalDataSheet.type_description,
      ...this.technicalDataSheet.ingredients,
      ...this.technicalDataSheet.allergies,
      ...this.technicalDataSheet.nutritionalvalue
    }

    this.messageService.createConfirmationDialog('Technische fiche opslaan?', '').subscribe(agrees => {
      if (!agrees) return

      this.saveTdsError = undefined
      this.tdsService.saveNewTds(newTds)
        .then(res => {
          this.authenticationService.unlockNavigation()
          this.router.navigate(['admin/tds'])
        })
        .catch(err => {
          this.saveTdsError = err
        })
    })
  }

  addCountry = () => {
    this.modalService.createAddCountryModal()
  }

  addConservation = () => {
    this.modalService.createAddConservationModal()
  }

  addShelflife = () => {
    this.modalService.createAddShelflifeModal()
  }

  formatAllergy = (allergy) => {
    switch (allergy) {
      case 'C': return '+'
      case 'NC': return '-'
      case 'MC': return '?'
      default: return null
    }
  }

  trackByFn = (index, item) => index // Very required function for performance
}
