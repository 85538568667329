import { mapValues, reduce, values } from 'lodash'

export const requiredFields = {
    general: {
        country_id: true,
        shelflife_id: true,
        conservation_id: true,
    },
    type_description: {
        type_description_nl: true,
        type_description_fr: true,
        type_description_en: false,
        type_description_de: false,
    },
    ingredients: {
        ingredients_nl: true,
        ingredients_fr: true,
        ingredients_en: false,
        ingredients_de: false,
    },
    allergies: {
        gluten: true,
        crustacean: true,
        egg: true,
        fish: true,
        groundnut: true,
        soy: true,
        milk: true,
        nuts: true,
        selery: true,
        mustard: true,
        sulfite: true,
        lupine: true,
        molluscs: true,
        sesame: false, // bug: not always returned by api
    },
    nutritionalvalue: {
        energy_kj: true,
        energy_kcal: true,
        fats: true,
        fats_saturated: true,
        fibers: true,
        sugars: true,
        proteins: true,
        salts: true,
        moisture: false,
        dry_matter: false,
        ash: false,
        sodium: false,
        ph: false,
        aw: false,
        total_kion_number: false,
        yeast: false,
        fungi: false,
        enterobacteriaceae: false,
        coliforms: false,
        salmonella: false,
        listeria_monocytogenes: false,
    }
}

/**
 * Returns document with true or false.
 * True: not required or not empty
 * False: Required and empty
 * @param technicalDataSheet Object, in sectioned format
 */
export function technicalDataSheetCompleted(technicalDataSheet) {
    const document = reduce(requiredFields, (document, value, key) => {
        document[key] = mapValues(value, (required, fieldName) => (technicalDataSheet[key][fieldName] && technicalDataSheet[key][fieldName] !== '') || !required)
        return document
    }, {})
    document['ok'] = !values(document).some(section => values(section).some(v => !v))
    document['reference'] = !(!technicalDataSheet['reference'] || technicalDataSheet['reference'] === '')
    if(!document['reference']) document['ok'] = false
    return document
}

/**
 * Changes the structure as received from server into document with section
 * @param tds Technical datasheet object
 */
export function changeTechnicalDataSheetStructure(tds) {
    return {
        id: tds.id,
        reference: tds.reference,
        general: {
            country_id: tds.country_id, //code will be set
            shelflife_id: tds.shelflife_id, //code will be set
            conservation_id: tds.conservation_id, //code will be set
        },
        type_description: {
            type_description_nl: tds.type_description_nl,
            type_description_fr: tds.type_description_fr,
            type_description_en: tds.type_description_en,
            type_description_de: tds.typedescription_de || tds.type_description_de,
        },
        ingredients: {
            ingredients_nl: tds.ingredients_nl,
            ingredients_fr: tds.ingredients_fr,
            ingredients_en: tds.ingredients_en,
            ingredients_de: tds.ingredients_de,
        },
        allergies: {
            gluten: tds.gluten,
            crustacean: tds.crustacean,
            egg: tds.egg,
            fish: tds.fish,
            groundnut: tds.groundnut,
            soy: tds.soy,
            milk: tds.milk,
            nuts: tds.nuts,
            selery: tds.selery,
            mustard: tds.mustard,
            sulfite: tds.sulfite,
            lupine: tds.lupine,
            molluscs: tds.molluscs,
        },
        nutritionalvalue: {
            energy_kj: tds.energy_kj,
            energy_kcal: tds.energy_kcal,
            fats: tds.fats,
            fats_saturated: tds.fats_saturated,
            fibers: tds.fibers,
            sugars: tds.sugars,
            proteins: tds.proteins,
            salts: tds.salts,
            moisture: tds.moisture,
            dry_matter: tds.dry_matter,
            ash: tds.ash,
            sodium: tds.sodium,
            ph: tds.ph,
            aw: tds.aw,
            total_kion_number: tds.total_kion_number,
            yeast: tds.yeast,
            fungi: tds.fungi,
            enterobacteriaceae: tds.enterobacteriaceae,
            coliforms: tds.coliforms,
            salmonella: tds.salmonella,
            listeria_monocytogenes: tds.listeria_monocytogenes,
        }
    }
}