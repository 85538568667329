import { Component, Inject } from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { BicobelHttpService } from 'src/app/api/bicobelHttp.service'
import { MessageService } from 'src/app/services/message.service'
import { ClientService } from 'src/app/services/client.service'

export interface DialogData {
    title: string
    text: string
}

@Component({
    selector: 'order-dialog',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss']
})
export class OrderModalComponent {

    orderDetails = {
        remarks: '',
        deliveryDate: '',
        deliveryAddressId: undefined
    }
    formError

    deliveryAddresses

    constructor(private messageService: MessageService,
        private clientService: ClientService,
        public dialogRef: MatDialogRef<OrderModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        this.clientService.castClientDeliveryAddress.subscribe(addresses => this.deliveryAddresses = addresses || [])
    }

    closeDialog = () => this.dialogRef.close()

    order() {
        if (!this.checkMinimalRequirements()) return
        this.messageService.createConfirmationDialog('MODAL.ORDER.SEND', 'MODAL.ORDER.CONFIRM_SEND_ORDER').subscribe(agrees => {
            if (!agrees) return
            this.dialogRef.close(this.orderDetails)
        })
    }

    checkMinimalRequirements() {
        this.formError = undefined
        if (!this.orderDetails.deliveryDate || this.orderDetails.deliveryDate === '') {
            this.formError = 'MODAL.ORDER.DELIVERY_DATE_REQUIRED'
            return false
        }
        if (!this.orderDetails.deliveryAddressId && this.deliveryAddresses.length !== 0) {
            this.formError = 'MODAL.ORDER.DELIVERY_ADDRESS_REQUIRED'
            return false
        }
        return true
    }

    getDeliveryDate() {
        return this.orderDetails.deliveryDate
    }
}