import { Component, Input, Output, ViewChild, ElementRef } from '@angular/core'
import { LanguageService } from 'src/app/services/language.service'
import { EventEmitter } from '@angular/core'

@Component({
  selector: 'labelSelect-dropdown',
  templateUrl: './labelSelect.component.html',
  styleUrls: ['./labelSelect.component.scss']
})
export class LabelSelectComponent {
  @ViewChild('input1') inputEl: ElementRef

  @Output('selected') emitter: EventEmitter<Object> = new EventEmitter<Object>()

  @Input('labels') set setInitoValue(labels) {
    this.labels = labels;
  }
  @Input('loading') set setInitoLoadingValue(loading) {
    if (loading) this.loading = loading
  }
  @Input('title') set setInitoTitleValue(title) {
    if (title) this.title = title
  }

  title
  loading = false
  labels
  filter
  selectedLabel

  constructor(private languageService: LanguageService) { }

  notify = () => {
    this.filter = ''
    this.emitter.emit(this.selectedLabel)
  }

  opened = () => setTimeout(() => {
    this.inputEl.nativeElement.focus()
  })
}
