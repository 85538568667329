import { Pipe, PipeTransform } from '@angular/core'
import { LanguageService } from '../services/language.service'

@Pipe({
    name: 'toEuDate'
})

export class EuropeDateFormatPipe implements PipeTransform {
    constructor(private languageService: LanguageService) { }
    transform(date: any) {
        if (date.constructor.name !== 'Date') return date
        const lang = this.languageService.getLanguage() === 'fr' ? 'fr-BE' : 'nl-BE'
        return date.toLocaleDateString(lang)
    }
}