import { values } from 'lodash'

export const checkLabelRequirements = (label) => {
    const formError = {
        code: undefined,
        description_nl: undefined,
        description_fr: undefined,
        price: undefined,
        amount: undefined,
        barcode: undefined,
        footer: undefined,
        complete: true
    }
    const { code, description_nl, description_fr, price, amount, barcode, footer } = label

    if (!code || code === '') {
        formError.code = 'LABEL.FORMERROR.NO_EMPTY_STRING'
        formError.complete = false
    }
    if (barcode.length !== 7) {
        formError.barcode = 'LABEL.FORMERROR.INVALID_BARCODE'
        formError.complete = false
    }
    return formError
}

export const calculateEan8Checksum = (eanCode) => {
    const errorCode = ''
    if (!eanCode || typeof eanCode !== 'string') return errorCode
    // Check if only digits
    const ValidChars = "0123456789";
    for (let i = 0; i < eanCode.length; i++) {
        const digit = eanCode.charAt(i);
        if (ValidChars.indexOf(digit) === -1) return errorCode
    }
    //check if 7 chars
    if (eanCode.length !== 7) return errorCode
    eanCode = "00000" + eanCode

    const checkSum = eanCode.split('').reduce(function (p, v, i) {
        return i % 2 == 0 ? p + 1 * v : p + 3 * v;
    }, 0);

    const controlDigit = 10 - (checkSum % 10)
    return controlDigit === 10 ? '' + 0 : '' + controlDigit
}