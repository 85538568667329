import { Injectable, OnDestroy } from '@angular/core'
import { BicobelHttpService } from 'src/app/api/bicobelHttp.service'
import { BehaviorSubject } from 'rxjs'
import { AuthenticationService } from './authentication.service'

@Injectable({
  providedIn: 'root',
})

export class AccountService implements OnDestroy {

  private userSubscription

  private accounts = new BehaviorSubject(undefined)
  castAccounts = this.accounts.asObservable()

  private accountsError = new BehaviorSubject(undefined)
  castAccountsError = this.accountsError.asObservable()

  private roles = new BehaviorSubject(undefined)
  castRoles = this.roles.asObservable()

  private rolesError = new BehaviorSubject(undefined)
  castRolesError = this.rolesError.asObservable()

  FILTERED_ROLES = ['labeluser', 'labelmanager']

  constructor(private bicobelHttpService: BicobelHttpService, private authenticationService: AuthenticationService) {
    this.init()
  }

  init = () => {
    this.userSubscription = this.authenticationService.castUser.subscribe(() => {
      this.reset()
      this.authenticationService.hasAccessToResource('account') && this.fetchAccounts()
      this.authenticationService.hasAccessToResource('role') && this.fetchRoles()
    })
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe()
  }

  reset = () => {
    this.accounts.next(undefined)
    this.roles.next(undefined)
    this.accountsError.next(undefined)
    this.rolesError.next(undefined)
  }

  fetchAccounts = async () => {
    return this.bicobelHttpService.get('/account')
      .then(accounts => {
        this.accounts.next(Object.values(accounts))
        this.accountsError.next(undefined)
        return accounts
      })
      .catch(error => {
        this.accountsError.next(error)
        throw error
      })
  }

  fetchRoles = () => {
    return this.bicobelHttpService.get('/role')
      .then(roles => {
        this.roles.next(Object.values(roles))
        this.rolesError.next(undefined)
        return roles
      })
      .catch(error => {
        this.rolesError.next(error)
        throw error
      })
  }

  getRoles = () => {
    return this.roles.value
  }

  getAccounts = () => {
    return this.accounts.value
  }

  createAccount = (account) => {
    return this.bicobelHttpService.post('/account', account)
      .then(id => {
        this.fetchAccounts()
        return id
      })
  }

  updateAccount = (account) => {
    return this.bicobelHttpService.put('/account', account)
      .then(this.fetchAccounts)
  }

  deleteAccount = (accountId: Number) => {
    return this.bicobelHttpService.delete(`/account/${Number(accountId)}`)
      .then(this.fetchAccounts)
  }

  createRole = (role) => {
    return this.bicobelHttpService.post('/role', role)
      .then(this.fetchRoles)
  }

  updateRole = (role) => {
    return this.bicobelHttpService.put('/role', role)
      .then(this.fetchRoles)
  }

  deleteRoleByName = (rolename) => {
    return this.bicobelHttpService.delete(`/role/${rolename}`)
      .then(this.fetchRoles)
  }

  addRolesToAccount = (accountid, rolenames = []) => {
    const promises = rolenames.map(rolename => this.bicobelHttpService.post(`/account/${accountid}/role/${rolename}`, {}))

    return Promise.all(promises)
      .then(this.fetchAccounts)
  }

  removeRolesForAccount = (accountid, rolename = []) => {
    const promises = rolename.map(rolename => this.bicobelHttpService.delete(`/account/${accountid}/role/${rolename}`))

    return Promise.all(promises)
      .then(this.fetchAccounts)
  }
}
