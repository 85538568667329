import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { MessageService } from 'src/app/services/message.service'
import { TdsService } from 'src/app/services/tds.service'
import { CountryService } from 'src/app/services/country.service'
import { ConservationService } from 'src/app/services/conservation.service'
import { ShelflifeService } from 'src/app/services/shelflife.service'
import { ModalService } from 'src/app/services/modal.service'
import { technicalDataSheetCompleted, changeTechnicalDataSheetStructure, requiredFields } from '../../../../lib/tds'
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'tdsEdit',
  templateUrl: './tdsEdit.component.html',
  styleUrls: ['./tdsEdit.component.scss']
})
export class TdsEditComponent implements OnInit {

  sub
  saveTdsError

  documentChecked

  technicalDataSheet

  countries = []
  shelflives = []
  conservations = []

  allExpanded = true

  constructor(private tdsService: TdsService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private countryService: CountryService,
    private conservationService: ConservationService,
    private shelflifeService: ShelflifeService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.initSubscriptions()
    this.authenticationService.lockNavigation()
  }

  private initSubscriptions = () => {
    this.countryService.castCountries.subscribe(countries => this.countries = countries)
    this.shelflifeService.castShelflives.subscribe(shelflives => this.shelflives = shelflives)
    this.conservationService.castConservations.subscribe(conservations => this.conservations = conservations)
  }

  ngOnInit () {
    this.sub = this.route.queryParams.subscribe(params => {
      const id = Number(params['id'])
      const tds = this.tdsService.getTdsById(id)
      if (!id || !tds) return this.router.navigate(['admin/tds'])
      this.technicalDataSheet = changeTechnicalDataSheetStructure(tds)
    })
  }

  ngOnDestroy(){
    this.sub.unsubscribe()
  }

  isRequired = (section, key) => {
    return requiredFields[section][key] || false
  }

  saveTDS = () => {
    this.documentChecked = technicalDataSheetCompleted(this.technicalDataSheet)
    if (!this.documentChecked['ok']) return

    const editedTds = {
      id: this.technicalDataSheet.id,
      reference: this.technicalDataSheet.reference,
      ...this.technicalDataSheet.general,
      ...this.technicalDataSheet.type_description,
      ...this.technicalDataSheet.ingredients,
      ...this.technicalDataSheet.allergies,
      ...this.technicalDataSheet.nutritionalvalue
    }

    this.messageService.createConfirmationDialog('Technische fiche opslaan?', '').subscribe(agrees => {
      if (!agrees) return
      this.saveTdsError = undefined
      this.tdsService.saveEditedTds(editedTds)
        .then(res => {
          this.authenticationService.unlockNavigation()
          this.router.navigate(['admin/tds'])
        }).catch(err => {
          this.saveTdsError = err
        })
    })
  }

  addCountry = () => {
    this.modalService.createAddCountryModal()
  }

  addConservation = () => {
    this.modalService.createAddConservationModal()
  }

  addShelflife = () => {
    this.modalService.createAddShelflifeModal()
  }

  formatAllergy = (allergy) => {
    switch (allergy) {
      case 'C': return '+'
      case 'NC': return '-'
      case 'MC': return '?'
      default: return null
    }
  }

  trackByFn = (index, item) => index // Very required function for performance
}
