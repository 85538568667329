import { Component, ViewChild, OnInit } from '@angular/core'
import { Router } from "@angular/router"
import { MessageService } from '../../../services/message.service'
import { LabelService } from '../../../services/label.service'
import { ProductService } from 'src/app/services/product.service'
import { LanguageService } from 'src/app/services/language.service'
import { AuthenticationService } from 'src/app/services/authentication.service'
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material'
import { Label } from '../../../interfaces/interfaces'

@Component({
    selector: 'labelOverview',
    templateUrl: './labelOverview.component.html',
    styleUrls: ['./labelOverview.component.scss']
})

export class LabelOverviewComponent implements OnInit {

    @ViewChild(MatPaginator) paginator: MatPaginator
    @ViewChild(MatSort) sort: MatSort

    filter
    locked = false

    WORDLENGTH = 100

    labels: MatTableDataSource<Label> = new MatTableDataSource([])
    labelsError

    errorDeletingLabel
    errorChangingPrintable

    constructor(
        private messageService: MessageService,
        private labelService: LabelService,
        private productService: ProductService,
        private router: Router,
        private languageService: LanguageService,
        private authenticationService: AuthenticationService
    ) {
        this.initSubscriptions()
    }

    private initSubscriptions = () => {
        this.labelService.castLabels.subscribe(labels => {
            this.labels.data = labels || []
        }, error => this.labelsError = error)
        this.labelService.castLabelsError.subscribe(error => this.labelsError = error)
    }

    ngOnInit() {
        this.labels.paginator = this.paginator
        this.labels.sort = this.sort
    }

    applyFilter = (filterValue: string) => {
        this.labels.filter = filterValue.trim().toLowerCase()

        if (this.labels.paginator) {
            this.labels.paginator.firstPage()
        }
    }

    hasClient = () => {
        return !!this.authenticationService.getClientId()
    }

    editLabelById = (id) => {
        this.router.navigate(['/label/edit'], { queryParams: { id: id } })
    }

    deleteLabelById = (id) => {
        this.messageService.createConfirmationDialog('LABEL.OVERVIEW.DELETE_LABEL_TITLE', 'LABEL.OVERVIEW.DELETE_LABEL').subscribe(agrees => {
            if (!agrees) return
            this.labelService.deleteLabelById(id)
                .then(res => {
                    this.errorDeletingLabel = undefined
                }).catch(err => {
                    this.errorDeletingLabel = err
                    this.messageService.new(err, 'alert')
                })
        })
    }

    giveInformation = (productid) => {
        const product = this.productService.getProductById(Number(productid))
        this.messageService.new(`LABEL.OVERVIEW.LABEL_MADE_FOR| ${product.description_nl} | ${product.description_fr}`, 'neutral', 10)
    }

    setPrintable = (label) => {
        this.locked = true
        this.labelService.flipLabelPrintable(label).then(res => {
            this.errorChangingPrintable = undefined
            this.locked = false
        }).catch(err => {
            this.errorChangingPrintable = err
            this.locked = false
        })
    }

    isLabelmanager = () => {
        return this.authenticationService.hasRole('labelmanager')
    }


}