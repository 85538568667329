import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material'
import { MessageService } from 'src/app/services/message.service'
import { CountryService } from 'src/app/services/country.service'

export interface DialogData {
    title: string
    text: string
}

@Component({
    selector: 'addCountry-dialog',
    templateUrl: './addCountry.component.html',
    styleUrls: ['./addCountry.component.scss']
})
export class AddCountryModalComponent {

    country = {
        code: null,
        name_nl: null,
        name_fr: null,
        name_en: null,
        name_de: null
    }
    formError
    addCountryError

    constructor(private messageService: MessageService,
        private countryService: CountryService,
        public dialogRef: MatDialogRef<AddCountryModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    closeDialog = () => this.dialogRef.close()

    addCountry() {
        if (!this.checkMinimalRequirements()) return
        this.messageService.createConfirmationDialog('Opslaan', 'Weet u zeker dat u dit land wilt toevoegen?').subscribe(agrees => {
            if (!agrees) return
            this.addCountryError = undefined
            this.countryService.addCountry(this.country)
                .then(() => {
                    this.closeDialog()
                }).catch(err => {
                    this.addCountryError = err
                })
        })
    }

    checkMinimalRequirements() {
        this.formError = undefined
        if (!this.country.code || !this.country.name_fr || !this.country.name_nl || !this.country.name_en || !this.country.name_de) {
            this.formError = 'Niet alle velden zijn ingevuld'
            return false
        }
        return true
    }
}