import { BrowserModule } from '@angular/platform-browser'
import { RouterModule, Routes } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { NgModule, APP_INITIALIZER } from '@angular/core'
import { FilterPipeModule } from 'ngx-filter-pipe'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'

//INTERCEPTORS
import { HttpConfigInterceptor } from './interceptors/http.interceptor'

//CUSTOM COMPONENTS
import { AppComponent } from './app.component'
import { HomeComponent } from './components/home/home.component'
import { FooterComponent } from './components/footer/footer.component'
import { HeaderComponent } from './components/header/header.component'
import { PageNotFoundComponent } from './components/pageNotFound/pageNotFound.component'
import { ContactComponent } from './components/contact/contact.component'
import { BasketComponent } from './components/basket/basket.component'
import { MessageComponent } from './components/message/message.component'
import { LabelComponent } from './components/label/label.component'
import { LabelNewComponent } from './components/label/new/labelNew.component'
import { LabelOverviewComponent } from './components/label/overview/labelOverview.component'
import { LabelPrintComponent } from './components/label/print/labelPrint.component'
import { LabelEditComponent } from './components/label/edit/labelEdit.component'
import { TdsNewComponent } from './partviews/tds/new/tdsNew.component'
import { TdsEditComponent } from './partviews/tds/edit/tdsEdit.component'
import { StoryNewComponent } from './partviews/story/new/storyNew.component'
import { StoryEditComponent } from './partviews/story/edit/storyEdit.component'
import { ShopComponent } from './components/shop/shop.component'
import { AdminComponent } from './components/admin/admin.component'
import { ProfileComponent } from './components/profile/profile.component'
import { ChangePasswordComponent } from './components/changePassword/changePassword.component'
import { FaqComponent } from './components/faq/faq.component'
import { TdsAsPdfListComponent } from './components/label/tdsAsPdfList/tdsAsPdfList.component'

//PARTVIEWS
import { ProductSelectComponent } from './partviews/productSelect/productSelect.component'
import { LabelSelectComponent } from './partviews/labelSelect/labelSelect.component'
import { AccountListComponent } from './partviews/accountList/accountList.component'
import { RolesListComponent } from './partviews/roleList/roleList.component'
import { ClientListComponent } from './partviews/clientList/clientList.component'
import { ShelflifeListComponent } from './partviews/shelflifeList/shelflifeList.component'
import { CountryListComponent } from './partviews/countryList/countryList.component'
import { ConservationListComponent } from './partviews/conservationList/conservationList.component'
import { TdsListComponent } from './partviews/tds/tdsList/tdsList.component'
import { ProductListComponent } from './partviews/productList/productList.component'
import { StoryListComponent } from './partviews/story/storyList/storyList.component'
import { RemindersComponent } from './partviews/reminders/reminders.component'
import { FilterComponent } from './partviews/filter/filter.component'

//CUSTOM MODAL COMPONENTS
import { LoginModalComponent } from './modals/login/login.component'
import { ConfirmationModalComponent } from './modals/confirmation/confirmation.component'
import { AddCountryModalComponent } from './modals/addCountry/addCountry.component'
import { AddConservationModalComponent } from './modals/addConservation/addConservation.component'
import { AddShelflifeModalComponent } from './modals/addShelflife/addShelflife.component'
import { OrderModalComponent } from './modals/order/order.component'
import { AddAccountModalComponent } from './modals/addAccount/addAccount.component'
import { AddRoleModalComponent } from './modals/addRole/addRole.component'
import { ResetPasswordModalComponent } from './modals/resetPassword/resetPassword.component'
import { ProductDetailsModalComponent } from './modals/productDetails/productDetails.component'
import { EditProductModalComponent } from './modals/editProduct/editProduct.component'
import { EditAccountModalComponent } from './modals/editAccount/editAccount.component'

//SERVICES
import { AuthenticationService } from './services/authentication.service'
import { ProductService } from './services/product.service'
import { LabelService } from './services/label.service'
import { BasketService } from './services/basket.service'
import { MessageService } from './services/message.service'
import { LanguageService } from './services/language.service'
import { StorageService } from './services/storage.service'
import { ModalService } from './services/modal.service'
import { ErrorHandlerService } from './api/errorHandler.service'
import { TdsService } from './services/tds.service'
import { StoryService } from './services/story.service'
import { ConservationService } from './services/conservation.service'
import { ShelflifeService } from './services/shelflife.service'
import { CountryService } from './services/country.service'
import { ClientService } from './services/client.service'

//UI
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatCardModule, MatSortModule } from '@angular/material'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatDividerModule } from '@angular/material/divider'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatButtonModule } from '@angular/material/button'
import { MatBadgeModule } from '@angular/material/badge'
import { MatSelectModule } from '@angular/material/select'
import { MatListModule } from '@angular/material/list'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatDialogModule } from '@angular/material/dialog'
import { MatMenuModule } from '@angular/material/menu'
import { MatRadioModule } from '@angular/material/radio'
import { MatTabsModule } from '@angular/material/tabs'
import { ReactiveFormsModule } from '@angular/forms'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatTableModule } from '@angular/material/table'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatIconModule } from '@angular/material/icon'
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime'
import { QuillModule } from 'ngx-quill'
import { ContentLoaderModule } from '@netbasal/content-loader'

//FILTERS
import { GenericObjectFilterPipe } from './filters/genericObjectFilter.pipe'
import { TranslatePipe } from './filters/translate.pipe'
import { SafePipe } from './filters/safe.pipe'
import { EuropeDateFormatPipe } from './filters/europeDateFormat.pipe'
import { CurrencyFormatPipe } from './filters/currency.pipe'
import { PaginationFilter } from './filters/paginationFilter.pipe'

//DIRECTIVES
import { DeferLoadDirective } from './directives/defer-load.directive'

export function setupTranslateFactory(service: LanguageService): Function {
  return () => service.fetchData()
}

const appRoutes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'shop', canActivate: [AuthenticationService], component: ShopComponent },
  {
    path: 'label', canActivate: [AuthenticationService], component: LabelComponent, children: [
      { path: '', redirectTo: 'print', pathMatch: 'full' },
      { path: 'print', canActivate: [AuthenticationService], component: LabelPrintComponent },
      { path: 'new', canActivate: [AuthenticationService], canDeactivate: [AuthenticationService], component: LabelNewComponent },
      { path: 'overview', canActivate: [AuthenticationService], component: LabelOverviewComponent },
      { path: 'edit', canActivate: [AuthenticationService], canDeactivate: [AuthenticationService], component: LabelEditComponent },
      { path: 'tdsAsPdfList', canActivate: [AuthenticationService], canDeactivate: [AuthenticationService], component: TdsAsPdfListComponent },
    ]
  },
  { path: 'faq', component: FaqComponent },
  { path: 'contact', component: ContactComponent },
  {
    path: 'admin', canActivate: [AuthenticationService], component: AdminComponent, children: [
      { path: 'account', canActivate: [AuthenticationService], component: AccountListComponent },
      { path: 'client', canActivate: [AuthenticationService], component: ClientListComponent },
      { path: 'role', canActivate: [AuthenticationService], component: RolesListComponent },
      { path: 'product', canActivate: [AuthenticationService], component: ProductListComponent },
      { path: 'country', canActivate: [AuthenticationService], component: CountryListComponent },
      { path: 'conservation', canActivate: [AuthenticationService], component: ConservationListComponent },
      { path: 'shelflife', canActivate: [AuthenticationService], component: ShelflifeListComponent },
      { path: 'tds', canActivate: [AuthenticationService], component: TdsListComponent },
      { path: 'tds/new', canActivate: [AuthenticationService], canDeactivate: [AuthenticationService], component: TdsNewComponent },
      { path: 'tds/edit', canActivate: [AuthenticationService], canDeactivate: [AuthenticationService], component: TdsEditComponent },
      { path: 'story', canActivate: [AuthenticationService], component: StoryListComponent },
      { path: 'story/new', canActivate: [AuthenticationService], canDeactivate: [AuthenticationService], component: StoryNewComponent },
      { path: 'story/edit', canActivate: [AuthenticationService], canDeactivate: [AuthenticationService], component: StoryEditComponent },
      { path: 'reminders', canActivate: [AuthenticationService], canDeactivate: [AuthenticationService], component: RemindersComponent },
    ]
  },
  { path: 'basket', canActivate: [AuthenticationService], component: BasketComponent },
  { path: 'profile', canActivate: [AuthenticationService], component: ProfileComponent },
  { path: 'changepassword', component: ChangePasswordComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
]

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    ContactComponent,
    BasketComponent,
    MessageComponent,
    LabelPrintComponent,
    LabelNewComponent,
    LabelOverviewComponent,
    LabelEditComponent,
    TdsAsPdfListComponent,
    TdsNewComponent,
    TdsEditComponent,
    StoryNewComponent,
    StoryEditComponent,
    ShopComponent,
    AdminComponent,
    ProfileComponent,
    ChangePasswordComponent,
    LabelComponent,
    FaqComponent,

    //PARTVIEWS
    ProductSelectComponent,
    LabelSelectComponent,
    TdsListComponent,
    ProductListComponent,
    AccountListComponent,
    RolesListComponent,
    ClientListComponent,
    ShelflifeListComponent,
    CountryListComponent,
    ConservationListComponent,
    StoryListComponent,
    RemindersComponent,
    FilterComponent,

    //MODAL COMPONENTS
    ConfirmationModalComponent,
    LoginModalComponent,
    AddCountryModalComponent,
    AddConservationModalComponent,
    AddShelflifeModalComponent,
    OrderModalComponent,
    AddAccountModalComponent,
    AddRoleModalComponent,
    ResetPasswordModalComponent,
    ProductDetailsModalComponent,
    EditProductModalComponent,
    EditAccountModalComponent,

    //FILTERS
    TranslatePipe,
    SafePipe,
    EuropeDateFormatPipe,
    CurrencyFormatPipe,
    GenericObjectFilterPipe,
    PaginationFilter,

    //DIRECTIVES
    DeferLoadDirective,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    NgbModule,
    HttpClientModule,

    //MATERIAL COMPONENTS
    MatCardModule,
    MatExpansionModule,
    MatDividerModule,
    MatGridListModule,
    MatButtonModule,
    MatBadgeModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatListModule,
    MatInputModule,
    FilterPipeModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatMenuModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatTableModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatIconModule,
    MatSortModule,

    //NOT MATERIAL COMPONENTS
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    QuillModule,
    ContentLoaderModule,

    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    ),
  ],
  entryComponents: [
    ConfirmationModalComponent,
    LoginModalComponent,
    AddCountryModalComponent,
    AddConservationModalComponent,
    AddShelflifeModalComponent,
    OrderModalComponent,
    AddAccountModalComponent,
    AddRoleModalComponent,
    ResetPasswordModalComponent,
    ProductDetailsModalComponent,
    EditProductModalComponent,
    EditAccountModalComponent
  ],
  providers: [
    AuthenticationService,
    ProductService,
    LabelService,
    BasketService,
    MessageService,
    StorageService,
    StoryService,
    ModalService,
    ErrorHandlerService,
    TdsService,
    ConservationService,
    ShelflifeService,
    CountryService,
    ClientService,
    LanguageService,

    //FILTERS AND PIPES
    GenericObjectFilterPipe,

    //FACTORY
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [LanguageService],
      multi: true
    },

    // INTERCEPTOR
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
